import React, { useState, useEffect, useRef } from 'react';
import { useLocation as useRouterLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { getTrainingType } from '../utils'; // Adjust the path as necessary
import EditUserDialog from './EditUserDialog';
import EditTraineeDialog from './EditTraineeDialog'; // Adjust the path as necessary
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

// Style for tables to ensure uniform width
const StyledTableContainer = styled(TableContainer)(({ theme, maxWidth }) => ({
  width: maxWidth || '100%',
  maxWidth: '1200px', // Adjust as needed
  margin: '0 auto',
  backgroundColor: 'transparent',
}));

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Adjust the format as needed
};

const fetchUserDetails = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_details', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.user_info[0] || {};
    } else {
if (isDebugMode)       console.error("Error fetching user details");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return {};
};

const fetchTrainers = async (adminID, token, userId) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_get_trainees_for_user', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userId}),
    });
    const jsonResponse = await response.json();
    if (isDebugMode)     console.log('admin_get_trainees_for_user:jsonResponse:', jsonResponse);

    if (jsonResponse.success) {
      return jsonResponse.trainers_info || [];
    } else {
if (isDebugMode)       console.error("Error fetching trainers");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchActiveCards = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_active_card', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'active' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const processedCards = jsonResponse.user_active_card.map(card => ({
        ...card,
        creation_time: formatDate(card.creation_time),
      }));
      return processedCards;
    } else {
if (isDebugMode)       console.error("Error fetching active cards");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchNonActiveCards = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_archive_cards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'inactive' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const processedCards = jsonResponse.user_archived_card.map(card => ({
        ...card,
        creation_time: formatDate(card.creation_time),
      }));
      return processedCards;
    } else {
if (isDebugMode)       console.error("Error fetching non-active cards");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchActiveTrainings = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_active_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'active' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const sortedTrainings = jsonResponse.user_active_training.sort((a, b) => b.training_session_id - a.training_session_id);
      return sortedTrainings;
    } else {
if (isDebugMode)       console.error("Error fetching active trainings");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

const fetchNonActiveTrainings = async (adminID, token, userID) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_user_archive_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token, user_id: userID, status: 'inactive' }),
    });
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      const sortedTrainings = jsonResponse.user_archived_training.sort((a, b) => b.training_session_id - a.training_session_id);
      return sortedTrainings;
    } else {
if (isDebugMode)       console.error("Error fetching non-active trainings");
    }
  } catch (error) {
if (isDebugMode)     console.error('Error:', error);
  }
  return [];
};

function AdminUserDetails() {
  const location = useRouterLocation();
  const { adminId, token, userId } = location.state || {};
  const [dialogOpen, setDialogOpen] = useState(false);
const [selectedUser, setSelectedUser] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [trainers, setTrainers] = useState([]);
  const [activeCards, setActiveCards] = useState([]);
  const [nonActiveCards, setNonActiveCards] = useState([]);
  const [activeTrainings, setActiveTrainings] = useState([]);
  const [nonActiveTrainings, setNonActiveTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maxTableWidth, setMaxTableWidth] = useState(0);
const [traineeDialogOpen, setTraineeDialogOpen] = useState(false);
const [selectedTrainee, setSelectedTrainee] = useState({});
  const theme = useTheme(); // Access the theme for color adjustments

  // Refs to access table containers
  const tableRefs = useRef([]);

const handleResetPassword = () => {
    if (isDebugMode)   console.log("Reset Password button clicked");
  // Implement reset password functionality
};

const handleEditTrainee = (trainee) => {
  setSelectedTrainee(trainee);
  setTraineeDialogOpen(true);
};

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        const user = await fetchUserDetails(adminId, token, userId);
        setUserDetails(user);
        const trainers = await fetchTrainers(adminId, token, userId);
        setTrainers(trainers);

        const activeCards = await fetchActiveCards(adminId, token, userId);
        setActiveCards(activeCards);
        const nonActiveCards = await fetchNonActiveCards(adminId, token, userId);
        setNonActiveCards(nonActiveCards);
        const activeTrainings = await fetchActiveTrainings(adminId, token, userId);
        setActiveTrainings(activeTrainings);
        const nonActiveTrainings = await fetchNonActiveTrainings(adminId, token, userId);
        setNonActiveTrainings(nonActiveTrainings);

      } catch (err) {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [adminId, token, userId]);

  // Calculate maximum table width
  useEffect(() => {
    const measureTables = () => {
      let maxWidth = 0;
      tableRefs.current.forEach(ref => {
        if (ref && ref.clientWidth > maxWidth) {
          maxWidth = ref.clientWidth;
        }
      });
      setMaxTableWidth(maxWidth);
    };

    measureTables();
    window.addEventListener('resize', measureTables);

    return () => window.removeEventListener('resize', measureTables);
  }, [trainers, activeCards, nonActiveCards, activeTrainings, nonActiveTrainings]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 0 }}>
        <Typography variant="h6" sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>
          פרטי משתמש
        </Typography>
        <Box sx={{ marginTop: 4, }}>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[0] = el}>
              <TableBody sx={{ marginTop: 4}}>
                <TableRow >
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שם</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>טלפון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>אימייל</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תיאור</TableCell>
                </TableRow>
                <TableRow onClick={() => {
        setSelectedUser(userDetails);
        setDialogOpen(true);
      }}>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{userDetails.name || ''}</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{userDetails.phone || ''}</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{userDetails.email || ''}</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{userDetails.description || ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
        <EditUserDialog
          open={dialogOpen}
          onClose={(updated, adminId, token, userId) => {
            setDialogOpen(false);
            if (updated) {
              // Fetch updated user details if necessary
              fetchUserDetails(adminId, token, userId).then(user => setUserDetails(user));
            }
          }}
          userDetails={selectedUser}
          adminId={adminId}
          token={token}
        />

        <EditTraineeDialog
          open={traineeDialogOpen}
          onClose={(updated) => {
            setTraineeDialogOpen(false);
            if (updated) {
              // Optionally fetch updated trainers if needed
              fetchTrainers(adminId, token, userId).then(trainers => setTrainers(trainers));
            }
          }}
          traineeDetails={selectedTrainee}
          adminId={adminId}
          token={token}
        />



        {/* Trainers Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6"  sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>רשימת מתאמנים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[1] = el}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שם</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>טלפון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>קבוצה</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שנתון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>קבוצות אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תיאור</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {trainers.map(trainer => (
                    <TableRow key={trainer.id} onClick={() => handleEditTrainee(trainer)}>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{trainer.name}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{trainer.phone}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{trainer.team}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{trainer.year}</TableCell>

                    <TableCell sx={{ color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>
                      {trainer.groups && trainer.groups.length > 0 ?
                        trainer.groups.map(group => group.group_name).join(', ') :
                        'No groups available'}
                    </TableCell>


                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{trainer.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Active Cards Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>כרטיסייות פעילות</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[2] = el}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תאריך</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>סוג אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מספר אימונים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>ניצול אימונים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>עלות</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שולם</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeCards.map(card => (
                  <TableRow key={card.id}>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.creation_time}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{getTrainingType(card.training_type)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.lessons_count}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.lessons_used}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.price}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.amount_paid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Non-Active Cards Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>היסטוריית כרטיסייות</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[3] = el}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תאריך</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>סוג אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מספר אימונים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>ניצול אימונים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>עלות</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שולם</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonActiveCards.map(card => (
                  <TableRow key={card.id}>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.creation_time}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{getTrainingType(card.training_type)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.lessons_count}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.lessons_used}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.price}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{card.amount_paid}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Active Trainings Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>אימונים פעילים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[4] = el}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תאריך</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שעה</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>סוג אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>קבוצת אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שחקן</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מאמן</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מיקום</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תיאור</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeTrainings.map(training => (
                  <TableRow key={training.id}>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{formatDate(training.date)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.time}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{getTrainingType(training.training_type)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.group_name}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.trainee_name}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.trainer}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.location}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.description}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>

        {/* Non-Active Trainings Table */}
        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6" sx={{ marginTop: 8, color: theme.palette.buttonBackground.contrastText}}>היסטוריית אימונים</Typography>
          <StyledTableContainer component={Paper} maxWidth={maxTableWidth}>
            <Table ref={el => tableRefs.current[5] = el}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תאריך</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שעה</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>סוג אימון</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מיקום</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מאמן</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שחקן</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>תיאור</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nonActiveTrainings.map(training => (
                  <TableRow key={training.id}>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{formatDate(training.date)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.time}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{getTrainingType(training.training_type)}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.location}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.trainer}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.trainee_name}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.description}</TableCell>
                    <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{training.card_pending === 1 ? 'לא שולם' : 'שולם'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      </Box>
    </Container>
  );
}

export default AdminUserDetails;
