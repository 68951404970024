import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EditTrainingDialog from './EditTrainingDialog';
import ManageUsersDialog from './ManageUsersDialog';
import InvitedUsersTable from './InvitedUsersTable';
import ApproveUsersDialog from './ApproveUsersDialog';
import { Grid, Paper, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { formatTime, formatDate, getTrainingType } from '../utils';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function TrainingDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { adminId, proId, token, trainingId } = location.state || {};
  const theme = useTheme(); // Using theme for styling

  const [tabIndex, setTabIndex] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openManageUsersDialog, setOpenManageUsersDialog] = useState(false);
  const [training, setTraining] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openApproveUsersDialog, setOpenApproveUsersDialog] = useState(false);
  const [trainerStatuses, setTrainerStatuses] = useState([]);


  const fetchTrainingDetails = async () => {
    setLoading(true);
    setError('');
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/get_pro_training`
        : `${process.env.REACT_APP_BASE_URL}api/get_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        setTraining(data.data[0] || null);
      } else {
        setError(data.message || 'Error fetching training details');
      }
    } catch (error) {
      setError('Error loading training details');
    } finally {
      setLoading(false);
    }
  };

  const fetchInvitedUsers = async () => {
    setLoading(true);
    setError('');
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/get_pro_users_to_training`
        : `${process.env.REACT_APP_BASE_URL}api/get_users_to_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        setInvitedUsers(data.users || []);
      } else {
        setError(data.message || 'Error fetching invited users');
      }
    } catch (error) {
      setError('Error loading invited users');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrainingDetails();
    fetchInvitedUsers();
  }, [adminId, proId, token, trainingId]);

useEffect(() => {
  if (training && training.available_trainers) {
    setTrainerStatuses(training.available_trainers.map(trainer => ({
      id: trainer.id,
      assigned: trainer.assign === 1, // Assuming 1 means assigned, 0 means not assigned
    })));
  }
}, [training]);

const handleSaveTrainerAssignments = async () => {
  const updatedTrainers = trainerStatuses.map((status) => ({
    id: status.id,
    assign: status.assigned ? 1 : 0, // Convert boolean to the required API format
  }));

      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/pro_add_remove_trainer_from_session`
        : `${process.env.REACT_APP_BASE_URL}api/add_remove_trainer_from_session`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
    training_id: trainingId,
    token,
    trainers: updatedTrainers,
  });

  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });

  const data = await response.json();
  if (data.success) {
    fetchTrainingDetails(); // Refresh training details after saving
  } else {
//    setError('Error saving trainer assignments');
  }
};


const handleCheckboxChange = (trainerId) => {
  setTrainerStatuses((prevStatuses) =>
    prevStatuses.map((status) =>
      status.id === trainerId
        ? { ...status, assigned: !status.assigned } // Toggle the assignment status
        : status
    )
  );
};


  const handleUpdateTraining = (updatedTraining) => {
    setTraining(updatedTraining[0] || null);
  };

  const handleAddUsers = () => {
    setOpenManageUsersDialog(true);
  };

  const handleEdit = () => {
    setOpenEditDialog(true);
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleDeleteConfirm = async () => {
    setOpenDeleteDialog(false);
    try {
      const url = proId
        ? `${process.env.REACT_APP_BASE_URL}api/delete_pro_training`
        : `${process.env.REACT_APP_BASE_URL}api/delete_training`;
      const body = JSON.stringify({
        ...(proId ? { pro_id: proId } : { admin_id: adminId }),
        token,
        training_id: trainingId,
      });

      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });

      const data = await response.json();
      if (data.success) {
        navigate(-1);
      } else {
        setError(data.message || 'Error deleting training');
      }
    } catch (error) {
      setError('Error deleting training');
    }
  };

  const handleDeleteCancel = () => {
    setOpenDeleteDialog(false);
  };

  const handleCommit = () => {
    setOpenApproveUsersDialog(true);
  };

  const refreshData = () => {
    fetchTrainingDetails();
    fetchInvitedUsers();
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!training) {
    return <Typography color="error">No training details available</Typography>;
  }

  const isCommitted = training.commited === 2;

return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
          alt="Logo"
          src={myLogo}
        />
        <Typography component="h1" variant="h5"
            sx={{
                color: theme.palette.buttonBackground.contrastText,
              }}
        >
          פרטי אימון
        </Typography>


        <Box sx={{ width: '100%', maxWidth: 600 }}>

    <Paper sx={{ backgroundColor: theme.palette.buttonBackground.main, padding: 2, boxShadow: 3 }}>
      <Grid container spacing={2}>
        {/* First row with 2 columns */}
        <Grid item xs={6}>
          <Typography variant="body1" sx={{ color: theme.palette.buttonBackground.contrastText }}>{formatTime(training.time) || 'N/A'}</Typography>
          <Typography variant="body1" sx={{ color: theme.palette.buttonBackground.contrastText }}>{formatDate(training.date) || 'N/A'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1" sx={{ color: theme.palette.buttonBackground.contrastText }}>{getTrainingType(training.training_type) || 'לא הוגדר סוג אימון'}</Typography>
          <Typography variant="body1" sx={{ color: theme.palette.buttonBackground.contrastText }}>{training.group_name || 'לא שוייך לקבוצת אימון'}</Typography>
        </Grid>

        {/* Second row with 2 columns */}
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: theme.palette.buttonBackground.contrastText }}>{training.location || 'לא הוגדר מיקום'}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" sx={{ color: theme.palette.buttonBackground.contrastText }}>{training.description ? training.description : ''}</Typography>
        </Grid>
      </Grid>
    </Paper>
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleAddUsers}
              disabled={isCommitted}
            >
              הזמנת מתאמן
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleEdit}
              disabled={isCommitted}
            >
              לערוך
            </Button>
            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleDelete}
              disabled={isCommitted}
            >
              למחוק
            </Button>


            <Button
              variant="contained"
              sx={{ mr: 1 }}
              onClick={handleCommit}
              disabled={isCommitted}
            >
              אישור ונעילת אימון
            </Button>

          </Box>


<Paper sx={{ backgroundColor: theme.palette.buttonBackground.main, padding: 2, boxShadow: 3, marginTop: 2 }}>
  <Typography variant="body1" sx={{ color: theme.palette.buttonBackground.contrastText }}>מאמנים</Typography>
  <Grid container spacing={2} direction="row">
    {trainerStatuses.length > 0 ? (
      trainerStatuses.map((status, index) => (
        <Grid item key={status.id} xs={4}>  {/* 4 means 3 items per row */}
          <FormControlLabel sx={{ color: theme.palette.primary.contrastText, marginTop: 2 }}
            control={
              <Checkbox sx={{ color: theme.palette.primary.main }}
                checked={status.assigned}  // Based on the assignment status
                onChange={() => handleCheckboxChange(status.id)}  // Toggle on change
              />
            }
            label={training.available_trainers.find(trainer => trainer.id === status.id)?.name}  // Get the trainer name from available_trainers
          />
        </Grid>
      ))
    ) : (
      <Typography>אין מאמנים זמינים</Typography>
    )}
  </Grid>
  <Button
    variant="contained"
    sx={{ mt: 2 }}
    onClick={handleSaveTrainerAssignments}
    disabled={isCommitted} // You can disable this button if the training is committed
  >
    שמור שינויים
  </Button>
</Paper>



        </Box>




        <InvitedUsersTable
          invitedUsers={invitedUsers}
          setInvitedUsers={setInvitedUsers} // Pass the updater function
          token={token}
          adminId={adminId}
          trainingId={training.id}
        />
      </Box>

      <EditTrainingDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        training={training}
        onUpdate={handleUpdateTraining}
        adminId={adminId}
        proId={proId}
        token={token}
        onRefresh={refreshData}
      />

      <ManageUsersDialog
        open={openManageUsersDialog}
        onClose={() => {
          setOpenManageUsersDialog(false);
          refreshData();
        }}
        onSave={() => setOpenManageUsersDialog(false)}
        adminId={adminId}
        proId={proId}
        token={token}
        trainingId={trainingId}
        trainingType={training?.training_type}
      />

      <ApproveUsersDialog
        open={openApproveUsersDialog}
        onClose={() => setOpenApproveUsersDialog(false)}
        adminId={adminId}
        proId={proId}
        token={token}
        trainingId={trainingId}
        invitedUsers={invitedUsers}
      />

      <Dialog open={openDeleteDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this training?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TrainingDetails;
