import React from 'react';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation } from 'react-router-dom';
import ActiveTrainings from './ActiveTrainings';
import PreviousTrainings from './PreviousTrainings';
import NavBar from '../NavBar'; // Import NavBar
import myLogo from '../../ez.jpeg';
import { useTheme } from '@mui/material'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function Training() {
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {};
  const [tabIndex, setTabIndex] = React.useState(1);
  const theme = useTheme(); // Access the theme

  React.useEffect(() => {
    if (isDebugMode) console.log('User ID:', userId);
    if (isDebugMode) console.log('Token:', token);
  }, [userId, token]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Main content area with scrolling enabled */}
      <Container
        component="main"
        maxWidth="md"
        className="users-page"
        sx={{ flex: 1, overflowY: 'auto', paddingBottom: 8 }} // Added scrolling and paddingBottom for the nav
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5">
            {/* Title or welcome message */}
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center' }}>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 600 }}>
              <Typography variant="h6" align="center"></Typography>
              <ActiveTrainings />
            </Box>
            <Box sx={{ mb: 2, width: '100%', maxWidth: 550 ,color: theme.palette.primary.contrastText, marginTop: 10}}>
              <Typography variant="h6" align="center">אימונים שהסתיימו</Typography>
              <PreviousTrainings userId={userId} token={token} />
            </Box>
          </Box>
        </Box>

        {/* Add extra space at the bottom to allow smooth scrolling */}
        <Box sx={{ height: 500 }} /> {/* This will add 100px of space at the bottom */}
      </Container>

      {/* NavBar placed at the bottom */}
      <NavBar
        userId={userId}
        token={token}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        sx={{ position: 'fixed', bottom: 0, width: '100%' }} // NavBar fixed to bottom
      />
    </Box>
  );
}

export default Training;
