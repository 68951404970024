// ActiveTrainings.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Switch,
  Snackbar,
  useTheme,
} from '@mui/material';
import {
  DateRange as DateRangeIcon,
  AccessTime as AccessTimeIcon,
  LocationOn as LocationOnIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { getTrainingType, formatTime, formatDate } from '../utils';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const fetchActiveTrainings = async (userId, token, navigate) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/user_active_training`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId, token }),
    });

    const data = await response.json();
    if (data.success) {
      return data.user_active_training;
    } else if (data.message === 'Invalid token or user ID.') {
      navigate('/login'); // Redirect to login
    }
    return [];
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
    return [];
  }
};

const handleSwitchChange = async (event, trainingId, currentStatus, userId, token, navigate, setTrainings, setSnackbar) => {
  const newStatus = currentStatus === 1 ? 0 : 1;
  const endpoint = newStatus === 1 ? 'api/user_commited' : 'api/user_uncommited';

  if (isDebugMode) {
    console.debug('Switch Change:', { event, trainingId, currentStatus, userId });
  }

  setTrainings(trainings =>
    trainings.map(training =>
      training.trainee_training_id === trainingId
        ? { ...training, committed: newStatus, loading: true }
        : training
    )
  );

  try {
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}${endpoint}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user_id: userId, token, user_training_id: trainingId }),
    });
    const data = await response.json();

    if (data.success) {
      //setSnackbar({ open: true, message: 'Status updated successfully!' });
      const updatedTrainings = await fetchActiveTrainings(userId, token, navigate);
      setTrainings(updatedTrainings);
    } else {
      throw new Error('Server response indicates failure');
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
    setSnackbar({ open: true, message: 'Error updating status.' });
    setTrainings(trainings =>
      trainings.map(training =>
        training.trainee_training_id === trainingId
          ? { ...training, committed: currentStatus, loading: false }
          : training
      )
    );
  }
};

const ActiveTrainings = ({ enableScroll = false }) => {
  const theme = useTheme();
  const [trainings, setTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });
  const navigate = useNavigate();
  const { state: { userId, token } = {} } = useLocation();

  useEffect(() => {
    const loadTrainings = async () => {
      setLoading(true);
      const activeTrainings = await fetchActiveTrainings(userId, token, navigate);
      setTrainings(activeTrainings);
      setLoading(false);
    };
    loadTrainings();
  }, [userId, token, navigate]);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

const renderTrainings = (
<Box
  sx={{
    display: enableScroll ? 'flex' : 'grid',  // Use 'flex' if enableScroll is true, otherwise 'grid'
    overflowX: enableScroll ? 'auto' : 'initial', // Horizontal scroll if enableScroll is true
    overflowY: enableScroll ? 'initial' : 'auto', // Vertical scroll if no horizontal scroll
    gap: 2,
    gridTemplateColumns: enableScroll ? 'initial' : 'repeat(auto-fill, minmax(250px, 1fr))',  // Grid layout only if no horizontal scroll
    flexWrap: enableScroll ? 'nowrap' : 'wrap',  // No wrapping if horizontal scroll is enabled
    scrollBehavior: 'smooth', // Smooth scroll behavior
    maxHeight: enableScroll ? 'initial' : '80vh',  // Max height to ensure effective scrolling when no horizontal scroll

    // Grid for desktop (PC), two rows
    '@media (min-width: 768px)': {
      gridTemplateRows: 'auto auto', // Two rows
      gridTemplateColumns: 'repeat(2, 1fr)', // Two columns
      // Apply centering for the last card if the number of cards is odd
      '& > :last-child': {
        justifySelf: 'center', // Center the last card in the grid
      },
    },

    // Media query for mobile screens to center the grid items
    '@media (max-width: 767px)': {
      gridTemplateColumns: '1fr', // One item per row on mobile
      justifyItems: 'center', // Center the grid items
    }
  }}
>


    {trainings.map(training => {
      const {
        trainee_training_id,
        trainee_name,
        date,
        time,
        location,
        committed,
        loading,
        training_type,
        group_name,
      } = training;

      return (
        <Card
          key={trainee_training_id}
          sx={{
            minWidth: 250,
            maxWidth: 250,
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 1)',  // צל קטן מסביב לכרטיס
            borderRadius: '20px',
            height: 350,
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.dialogBackground.main,  // השתמש בצבע הרקע של הדיאלוגים
            gap: 1,
            color: theme.palette.dialogBackground.contrastText,
            marginBottom: 4,
          }}
        >
          <CardContent sx={{ flex: 1, textAlign: 'right', display: 'flex', flexDirection: 'column', backgroundColor: theme.palette.dialogBackground.main, borderRadius: '12px' }}>
            {/* שורה עם שם מרכזי */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1 }}>
              <Typography variant="h6">{trainee_name}</Typography>
            </Box>

            {/* שורה עם תאריך */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <DateRangeIcon sx={{ ml: 1, color: theme.palette.primary.main }} />
              <Typography variant="h6">{formatDate(date)}</Typography>
            </Box>

            {/* שורה עם שעה */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <AccessTimeIcon sx={{ ml: 1, color: theme.palette.primary.main }} />
              <Typography variant="h6">{formatTime(time)}</Typography>
            </Box>

{/* שורה עם מקום */}
<Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
  <LocationOnIcon sx={{ ml: 1, color: theme.palette.primary.main }} />
  <Typography
    variant="h6"
    sx={{
      wordWrap: "break-word", // Break long words if necessary
      whiteSpace: "normal",   // Allow text to wrap
      overflow: "visible",    // Let the text expand naturally
    }}
  >
    {location}
  </Typography>
</Box>

            {/* Switch - התחייבות */}
            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
              <Switch
                checked={committed === 1 || committed === 2}
                onChange={(event) => handleSwitchChange(event, trainee_training_id, committed, userId, token, navigate, setTrainings, setSnackbar)}
                color="primary"
                disabled={loading}
              />
              <Typography variant="body2" sx={{ ml: 1 }}>
                {committed === 2 ? 'מגיע' : (committed === 1 ? 'מגיע' : 'לא מגיע')}
              </Typography>
            </Box>

            {/* סוג האימון */}
            <Typography variant="body2" sx={{ mt: 2 }}>{getTrainingType(training_type)}</Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>{group_name}</Typography>

          </CardContent>
        </Card>
      );
    })}
  </Box>
);


  return (
    <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
      {loading ? (
        <Typography>טוען...</Typography>
      ) : trainings.length > 0 ? (
        renderTrainings
      ) : (
        <Typography>אין אימונים פעילים.</Typography>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message={snackbar.message}
      />
    </Box>
  );
};

export default ActiveTrainings;
