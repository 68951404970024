import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getMissingCard = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/users_with_trainings_no_cards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.users || [];
    } else {
      if (isDebugMode) console.error("Error fetching missing card");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

function TrainingsNoCard() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [filter, setFilter] = useState({ name: '', phone: '', email: '' });
  const [missingCard, setMissingCard] = useState([]);
  const theme = useTheme(); // Access the theme for color adjustments


  const fetchData = async () => {
    const missingCardData = await getMissingCard(adminId, token);
    setMissingCard(missingCardData);
  };

  useEffect(() => {
    if (isDebugMode) console.log('missingCard:', missingCard);
    if (isDebugMode) console.log('Type of missingCard:', typeof missingCard);
    fetchData();
  }, [adminId, token]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleRowClick = (userId) => {
      navigate('/admin_user_details', { state: { adminId, token, userId } });
  };

const filteredUsers = Array.isArray(missingCard) ? missingCard.filter(user => {
    const phoneMatch = user.phone.toLowerCase().includes(filter.phone.toLowerCase());
    const emailMatch = user.email.toLowerCase().includes(filter.email.toLowerCase());
    const nameMatch = user.name.toLowerCase().includes(filter.name.toLowerCase());
    return phoneMatch && emailMatch && nameMatch;
}) : [];


  return (

      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ color: theme.palette.dialogBackground2.contrastText }}>
            אימונים ללא כרטיסייה
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 600 }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="סנן לפי שם"
                variant="outlined"
                size="small"
                name="name"
                value={filter.name}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי מייל"
                variant="outlined"
                size="small"
                name="email"
                value={filter.email}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי טלפון"
                variant="outlined"
                size="small"
                name="phone"
                value={filter.phone}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
            </Box>

            <Box sx={{ maxHeight: 400, overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>

                    <th style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText}}>שם</th>
                    <th style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>מייל</th>
                    <th style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>טלפון</th>
                    <th style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>מספר אימונים</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredUsers.map(user => (
                    <tr key={user.id} onClick={() => handleRowClick(user.id)} style={{ cursor: 'pointer' }}>
                      <td style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>{user.name}</td>
                      <td style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>{user.email}</td>
                      <td style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>{user.phone}</td>
                      <td style={{ padding: '8px', borderBottom: `2px solid ${theme.palette.primary.main}` , color: theme.palette.buttonBackground.contrastText }}>{user.pending_trainings_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default TrainingsNoCard;
