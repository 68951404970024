import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import { useNavigate } from "react-router-dom";

const TrainingCard = ({ lessonsUsed, totalLessons, cardTitle, cardId, userId, token }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  // Dynamically calculate the numbers array based on totalLessons
  const numbers = Array.from({ length: totalLessons }, (_, i) => i + 1);

  // Dynamically calculate used trainings
  const usedTrainings = Array.from({ length: lessonsUsed }, (_, i) => i + 1);

  const handleCardClick = () => {
    navigate('/user_card_details', { state: { userId, token, cardId } });
  };

  return (
    <Box
      onClick={handleCardClick} // Attach the click handler
      sx={{
        width: 200,
        height: 350,
        backgroundColor: theme.palette.dialogBackground.main,
        borderRadius: "10px",
        padding: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
        cursor: "pointer", // Add pointer cursor for clickable effect
        transition: "transform 0.2s", // Add hover effect
        "&:hover": {
          transform: "scale(1.05)",
        },
      }}
    >
      {/* Card Title */}
      <Typography
        sx={{
          color: theme.palette.primary.contrastText,
          fontSize: "18px",
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: 2,
        }}
      >
        {cardTitle}
      </Typography>

      {/* Grid of Numbers */}
      <Grid
        container
        spacing={1} // Adjust the spacing between numbers
        justifyContent="center"
        sx={{ marginBottom: 50 }} // Add margin after numbers grid
      >
        {numbers.map((num) => (
          <Grid
            item
            xs={4}
            key={num}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: usedTrainings.includes(num)
                  ? theme.palette.primary.main
                  : theme.palette.dialogBackground.contrastText,
                color: usedTrainings.includes(num)
                  ? theme.palette.primary.contrastText
                  : theme.palette.secondary.contrastText,
                width: 40,
                height: 40,
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {num}
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Bottom Icon and Text */}
      <Box
        sx={{
          position: "absolute",
          bottom: 20,
          textAlign: "center",
        }}
      >
        <SportsSoccerIcon sx={{ fontSize: 50, color: theme.palette.primary.main }} />
        <Typography
          sx={{
            color: theme.palette.primary.contrastText,
            fontSize: "16px",
            fontWeight: "bold",
            marginTop: 1,
          }}
        >
          EZ Academy
        </Typography>
        <Typography sx={{ color: theme.palette.primary.main, fontSize: "12px" }}>
          תתמיד, תשקיע - תמריא
        </Typography>
      </Box>
    </Box>
  );
};

export default TrainingCard;
