import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'; // Import Button
import { formatDate, formatTime, getTrainingType } from '../utils'; // Assume you have these functions
import AddCardDialog from './AddCardDialog'; // Import AddCardDialog component
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const CardDetails = ({ adminId, token, cardId }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  const [cardTrainings, setCardTrainings] = useState([]);
  const [cardPayments, setCardPayments] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState(''); // Define dialogType and setDialogType
    const theme = useTheme(); // Access the theme for color adjustments


  // Function to fetch card details
  const fetchCardDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_card_details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: adminId, token, card_id: cardId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        if (isDebugMode) console.log('data:', data);
        setCardDetails(data.card_data.card_details || null);
        setCardTrainings(data.card_data.trainings || []);
        setCardPayments(data.card_data.payments || []);
      } else {
        setError('Failed to fetch card details');
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCardDetails();
  }, [adminId, token, cardId]);

  // Function to handle card cancellation
  const handleCancel = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/admin_cancel_card', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: adminId, token, card_id: cardId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        // Refresh card details after successful cancellation
        fetchCardDetails();
      } else {
        setError('Failed to cancel card');
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  const handlePay = () => {
    // Add functionality for handling payment
if (isDebugMode)     console.log('Handle payment1');
    setDialogOpen(true);
    setDialogType('payment'); // או 'card' במקרה של יצירת כרטיסייה

  };
  const handleDialogClose = (updated) => {
  setDialogOpen(false);
  if (updated) {
    fetchCardDetails(); // רענן את פרטי הכרטיסייה לאחר הוספת כרטיסייה חדשה
  }
};

  // Determine if the cancel button should be disabled
  const isCancelDisabled = cardDetails?.is_cancel === 1;

  return (
    <Box>
      {/* Card Details Table */}
      <TableContainer component={Paper} sx={{ marginBottom: 4, backgroundColor: theme.palette.buttonBackground.main }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                ששולם
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                מחיר
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                מספר אימונים
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                אימונים שנוצלו
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                סוג אימון
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                תאריך רכישה
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {cardDetails.amount_paid}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {cardDetails.price}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {cardDetails.lessons_count}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {cardDetails.lessons_used}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {getTrainingType(cardDetails.training_type)}
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                {formatDate(cardDetails.creation_time)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ mt: 2 }}>
        <Button variant="contained" sx={{ mr: 1 }} onClick={handlePay}>
          הוספת תשלום
        </Button>
        <Button
          variant="contained"
          sx={{
            mr: 1,
            backgroundColor: isCancelDisabled ? 'grey' : theme.palette.primary.main,
          }}
          onClick={isCancelDisabled ? null : handleCancel}
          disabled={isCancelDisabled}
        >
          ביטול כרטיסייה
        </Button>
      </Box>

      {/* Card Trainings Table */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4, textAlign: 'right', color: theme.palette.primary.contrastText }}>
        אימונים
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.buttonBackground.main }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                תיאור
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                מיקום
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                מתאמן
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                שעה
              </TableCell>
              <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                תאריך
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardTrainings.length > 0 ? (
              cardTrainings.map((training) => (
                <TableRow key={training.session_id}>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                    {training.description}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                    {training.location}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                    {training.trainee_name}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                    {formatTime(training.time)}
                  </TableCell>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText }}>
                    {formatDate(training.date)}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} sx={{ textAlign: 'center', color: theme.palette.primary.contrastText }}>
                  אין אימונים להצגה
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Card Payments Table */}
      <Typography variant="h6" gutterBottom sx={{ marginTop: 4, textAlign: 'right', color: theme.palette.primary.contrastText }}>
        תשלומים
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.buttonBackground.main }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>תיאור</TableCell>
              <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>סכום</TableCell>
              <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>תאריך</TableCell>
              <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>שיטת תשלום</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cardPayments.length > 0 ? (
              cardPayments.map((payment, index) => (
                <TableRow key={index}>
                  <TableCell sx={{ textAlign: 'right', color: theme.palette.primary.contrastText}}>{payment.descriptions}</TableCell>
                  <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>{payment.pay}</TableCell>
                  <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>{formatDate(payment.creation_date)}</TableCell>
                  <TableCell sx={{ textAlign: 'right' , color: theme.palette.primary.contrastText}}>{payment.type_paid}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                  אין היסטוריית תשלומים
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

        <AddCardDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          adminId={adminId}
          token={token}
          cardId={cardId}
          dialogType={dialogType}
          initialValues={{
            amountPaid: cardDetails.amount_paid,
            price: cardDetails.price,
            type: cardDetails.training_type,
            lessonsCount: cardDetails.lessons_count,
            typePaid: 'מזומן', // או ברירת מחדל אחרת
          }}
        />
    </Box>
  );
};

export default CardDetails;
