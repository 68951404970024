import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Link from '@mui/material/Link'; // Add this line to import Link
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';


export default function SignIn() {
  const navigate = useNavigate();
  const location = useLocation();
  const [token, setToken] = React.useState('');
  const theme = useTheme();

  React.useEffect(() => {
    const query = new URLSearchParams(location.search);
    setToken(query.get('token') || '');
  }, [location.search]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/first_signin_pro', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, token }),
      });
      const jsonResponse = await response.json();
      if (jsonResponse.success) {
        navigate('/pro_trainings', { state: { proId: jsonResponse.pro_id, token: jsonResponse.token
                , training_type: jsonResponse.training_type} });
      } else {
        if (isDebugMode) console.error("Login failed");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
  };

  return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar
            sx={{ m: 4, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ marginTop: 0,color: 'primary.contrastText' }}>
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="אימייל"
              name="email"
              autoComplete="email"
              autoFocus
            InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="סיסמה"
              type="password"
              id="password"
              autoComplete="current-password"
            InputProps={{
                style: { color: theme.palette.primary.contrastText }, // Text in white
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // Label in white
              }}
              sx={{
                direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                  borderBottom: `2px solid ${theme.palette.primary.main}`,
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,  // Set border to 4px when focused
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Background color
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              כניסה
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  איפוס סיסמה
                </Link>
              </Grid>
              <Grid item>
                <Link href="/signup_pro" variant="body2">
                  {"אין לך חשבון? הירשם"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
  );
}
