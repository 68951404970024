import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Snackbar, Alert } from '@mui/material';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function ManageUsersDialog({ open, onClose, onSave, onUpdateInvitedUsers,
            adminId, proId, token, trainingId, trainingType }) {
  const [users, setUsers] = useState([]);
  const [invitedUsers, setInvitedUsers] = useState(new Set());
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  const [filterName, setFilterName] = useState('');
  const [filterMinAge, setFilterMinAge] = useState('');
  const [filterMaxAge, setFilterMaxAge] = useState('');
  const [filterGroup, setFilterGroup] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [error, setError] = useState(null); // State for error message

  useEffect(() => {
    if (open) {
      fetchUsers();
      fetchInvitedUsers();
    }
  }, [open]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/trainees`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ admin_id: adminId, pro_id: proId, token: token })
      });
      const data = await response.json();
      if (isDebugMode) console.log(data);

      if (data.success) {
            if (isDebugMode) console.log(data.trainees);
        setUsers(data.trainees || []);
      } else {
        if (isDebugMode) console.error('Failed to fetch users:', data.message);
        setUsers([]);
      }
    } catch (error) {
      if (isDebugMode) console.error('Error fetching users:', error);
      setUsers([]);
    }
  };

  const fetchInvitedUsers = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/get_users_to_training`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ admin_id: adminId, pro_id: proId, token: token, training_id: trainingId })
      });
      const data = await response.json();
      if (data.success) {
        const usersList = data.users || [];
        setInvitedUsers(new Set(usersList.map(user => user.id)));
        setSelectedUsers(prevSelected => new Set([...prevSelected, ...usersList.map(user => user.id)]));
      } else {
        if (isDebugMode) console.error('Failed to fetch invited users:', data.message);
        setInvitedUsers(new Set());
      }
    } catch (error) {
      if (isDebugMode) console.error('Error fetching invited users:', error);
      setInvitedUsers(new Set());
    }
  };

  const handleSave = async () => {
    const numSelectedUsers = selectedUsers.size;

    // Error handling for personal and pair trainings
    if (trainingType === 1 && numSelectedUsers > 1) { // Personal training
      setError('שגיאה - בחירה מרובה');
      return;
    }
    if (trainingType === 2 && numSelectedUsers > 2) { // Pair training
      setError('שגיאה - בחירה מרובה');
      return;
    }

    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}api/add_trainee_to_training`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          admin_id: adminId,
          pro_id: proId,
          token: token,
          training_id: trainingId,
          trainee_ids: Array.from(selectedUsers),
          trainingType:trainingType
        })
      });
      if (isDebugMode) console.log(`Number of selected users: ${selectedUsers.size}`);
      onSave(); // Notify parent component to refresh or update
      if (onUpdateInvitedUsers) {
        onUpdateInvitedUsers(); // Notify parent component to refresh the invited users list
      }
      onClose(); // Close the dialog
    } catch (error) {
      if (isDebugMode) console.error('Error saving users:', error);
    }
  };

  const handleUserChange = (userId) => {
    setSelectedUsers(prev => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
        if (isDebugMode) console.log(`User ${userId} deselected`);
      } else {
        newSet.add(userId);
        if (isDebugMode) console.log(`User ${userId} selected`);
      }
      if (isDebugMode) console.log(`Number of selected users: ${newSet.size}`);
      return newSet;
    });
  };

const filteredUsers = users.filter(user => {
  if (isDebugMode) console.log("user");
  if (isDebugMode) console.log(user);

  // Ensure user.name and filterName are valid strings before using toLowerCase
  const nameMatch = user.name && user.name.toLowerCase().includes(filterName ? filterName.toLowerCase() : '');

  // Ensure user.year is a valid number before comparison
  const age = user.year;
  const ageMatch = (typeof age === 'number' && age >= (filterMinAge || 0) && age <= (filterMaxAge || Infinity));

  // Ensure user.team and filterGroup are valid strings before using toLowerCase
  const groupMatch = user.team && user.team.toLowerCase().includes(filterGroup ? filterGroup.toLowerCase() : '');

  if (isDebugMode) console.log(nameMatch);
  if (isDebugMode) console.log(ageMatch);
  if (isDebugMode) console.log(groupMatch);

  return nameMatch && groupMatch;
});


  const handleCloseError = () => {
    setError(null);
  };

  const isSaveDisabled = (trainingType === 1 && selectedUsers.size > 1) ||
                         (trainingType === 2 && selectedUsers.size > 2);

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>הוספת מתאמנים</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', gap: 2, mb: 2, flexWrap: 'wrap' }}>

          <TextField
            label="קבוצה"
            variant="outlined"
            size="small"
            value={filterGroup}
            onChange={(e) => setFilterGroup(e.target.value)}
            sx={{ width: 100, textAlign: 'right' }}
            InputLabelProps={{ style: { fontSize: '0.80rem' } }}
          />
          <TextField
            label="עד שנתוןהוספת מתאמנים"
            type="number"
            variant="outlined"
            size="small"
            value={filterMaxAge}
            onChange={(e) => setFilterMaxAge(e.target.value)}
            sx={{ width: 80, textAlign: 'right' }}
            InputLabelProps={{ style: { fontSize: '0.80rem' } }}
          />
          <TextField
            label="משנתון"
            type="number"
            variant="outlined"
            size="small"
            value={filterMinAge}
            onChange={(e) => setFilterMinAge(e.target.value)}
            sx={{ width: 80, textAlign: 'right' }}
            InputLabelProps={{ style: { fontSize: '0.80rem' } }}
          />
          <TextField
            label="לפי שם"
            variant="outlined"
            size="small"
            value={filterName}
            onChange={(e) => setFilterName(e.target.value)}
            sx={{ width: 120, textAlign: 'right' }}
            InputLabelProps={{ style: { fontSize: '0.80rem' } }}
          />
        </Box>
        <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="right" padding="checkbox">
                  <Checkbox
                    checked={selectAll}
                    onChange={(e) => setSelectAll(e.target.checked)}
                  />
                </TableCell>
                <TableCell align="right">שם</TableCell>
                <TableCell align="right">שנתון</TableCell>
                <TableCell align="right">קבוצה</TableCell>
                <TableCell align="right">טלפון</TableCell>
                <TableCell align="right">תיאור</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map(user => (
                <TableRow key={user.id}>
                  <TableCell align="right" padding="checkbox">
                    <Checkbox
                      checked={selectedUsers.has(user.id)}
                      onChange={() => handleUserChange(user.id)}
                    />
                  </TableCell>
                  <TableCell align="right">{user.name}</TableCell>
                  <TableCell align="right">{user.year}</TableCell>
                  <TableCell align="right">{user.team}</TableCell>
                  <TableCell align="right">{user.phone}</TableCell>
                  <TableCell align="right">{user.description}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} >סגור</Button>
        <Button onClick={handleSave} disabled={isSaveDisabled}>שמור</Button>
      </DialogActions>
      {error && (
        <Snackbar open={Boolean(error)} autoHideDuration={6000} onClose={handleCloseError}>
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}
    </Dialog>
  );
}

export default ManageUsersDialog;
