import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getProsInfo = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_admin_trainers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.trainers || [];
    } else {
      if (isDebugMode) console.error("Error fetching trainers info");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

function AdminProsInfo() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [prosInfo, setProsInfo] = useState([]);
  const [filter, setFilter] = useState({ name: '', phone: '', email: '' });
  const [tabIndex, setTabIndex] = useState(1);
  const theme = useTheme(); // Using theme for styling

  const fetchData = async () => {
    const pros = await getProsInfo(adminId, token);
    setProsInfo(pros);
  };

  useEffect(() => {
    fetchData();
  }, [adminId, token]);

  const handleTabChange = (event, newValue) => {
    if (isDebugMode) console.log('handleTabChange', newValue);
    switch (newValue) {
      case 0:
        navigate('/admin_trainings', { state: { adminId, token } });
        break;
      case 1:
        navigate('/management', { state: { adminId, token } });
        break;
      default:
        break;
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleRowClick = (proId) => {
    navigate('/admin_trainer_details', { state: { adminId, token, proId } });
  };

  return (

      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
           <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5"
            sx={{color: theme.palette.buttonBackground.contrastText}}>
            פרטי מאמנים
          </Typography>

          <Box sx={{ width: '100%', maxWidth: 600 }}>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 , margin: 4}}>
              <TextField
                label="סנן לפי טלפון"
                variant="outlined"
                size="small"
                name="phone"
                value={filter.phone}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי מייל"
                variant="outlined"
                size="small"
                name="email"
                value={filter.email}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
              <TextField
                label="סנן לפי שם "
                variant="outlined"
                size="small"
                name="name"
                value={filter.name}
                onChange={handleFilterChange}
                InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}
              />
            </Box>
            <Box sx={{ maxHeight: 800, overflowY: 'auto' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' , marginTop: 15}}>
                <thead>

                  <tr>
                    <th style={{ textAlign: 'right' ,padding: '8px', borderBottom: `3px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>שם</th>
                    <th style={{ padding: '8px', borderBottom: `3px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>סוג אימון</th>
                    <th style={{ padding: '8px', borderBottom: `3px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>מייל</th>
                    <th style={{ padding: '8px', borderBottom: `3px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>מספר</th>
                  </tr>
                </thead>
                <tbody>
                  {prosInfo
                    .filter(pro => {
                      const phoneMatch = (pro && pro.phone)? pro.phone.toLowerCase().includes(filter.phone.toLowerCase()) : "N/A";
                      const emailMatch = (pro && pro.email)? pro.email.toLowerCase().includes(filter.email.toLowerCase()) : "N/A";
                      const nameMatch = (pro && pro.name)? pro.name.toLowerCase().includes(filter.name.toLowerCase()) : "N/A";
                       return nameMatch && phoneMatch && emailMatch;
                    })
                    .map(pro => (
                      <tr key={pro.id} onClick={() => handleRowClick(pro.id)} style={{ cursor: 'pointer' }}>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>{pro.name}</td>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>{pro.training_type_labels.join(', ')}</td>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>{pro.email}</td>
                        <td style={{ padding: '8px', borderBottom: `1px solid ${theme.palette.primary.main}` ,color: theme.palette.primary.contrastText}}>{pro.phone}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Container>
  );
}

export default AdminProsInfo;
