import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme
import Box from '@mui/material/Box';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getUniforms = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_uniform_orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.uniform_orders || [];
    } else {
      if (isDebugMode) console.error("Error fetching uniform orders");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

function PayUniform() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [uniforms, setUniforms] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUniform, setSelectedUniform] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const theme = useTheme(); // Access the theme for color adjustments

  const fetchData = async () => {
    const uniformsData = await getUniforms(adminId, token);
    setUniforms(uniformsData);
  };

  useEffect(() => {
    fetchData();
  }, [adminId, token]);

  const handleRowClick = (uniform) => {
    // Prevent opening dialog if uniform.id is 1
    if (uniform.is_paid === 1) return;

    setSelectedUniform(uniform);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedUniform(null);
    setPaymentMethod(''); // Reset payment method
  };

  const handlePayment = async () => {
    console.log('Processing payment for:', selectedUniform);
    console.log('Selected payment method:', paymentMethod);

    // TODO: Call to process.env.REACT_APP_BASE_URL + 'api/pay_uniform_order'
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/pay_uniform_order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          admin_id: adminId,
          token,
          uniform_id: selectedUniform.id,
          payment_method: paymentMethod,
          total_price: selectedUniform.total_price,
        }),
      });
      const jsonResponse = await response.json();
      if (isDebugMode) console.log(jsonResponse);
      if (jsonResponse.success) {
        handleCloseDialog();
        fetchData();
        return;
      } else {
        if (isDebugMode) console.error("Error pay_uniform_order");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
    handleCloseDialog();
  };

  return (
      <Container component="main" maxWidth="md">
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography component="h1" variant="h5" sx={{ marginTop: 2, color: theme.palette.buttonBackground.contrastText}}>
            מדי אימון
          </Typography>

          <TableContainer sx={{ marginTop: 2}}>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>ID</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>שם</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>חולצות</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>מכנסיים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>גרביים</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>לתשלום</TableCell>
                  <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>סטטוס</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniforms.length > 0 ? (
                  uniforms.map(uniform => (
                    <TableRow
                      key={uniform.id}
                      onClick={() => handleRowClick(uniform)}
                      sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }}
                    >
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.id}</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.name} ({uniform.email})</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.shirt_quantity}</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.pants_quantity}</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.socks_quantity}</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.total_price}</TableCell>
                      <TableCell sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>{uniform.status}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center" sx={{color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}>
                      No uniform orders available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Dialog for displaying detailed order info */}
          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Order Details</DialogTitle>
            <DialogContent>
              <Typography variant="h6">Order ID: {selectedUniform?.id}</Typography>
              <Typography variant="body1">
                <strong>Total Price:</strong> {selectedUniform?.total_price}
              </Typography>
              <Typography variant="body1">
                <strong>Status:</strong> {selectedUniform?.status}
              </Typography>
              <Typography variant="body1">
                <strong>Shirt Quantity:</strong> {selectedUniform?.shirt_quantity} (Size: {selectedUniform?.shirt_size})
              </Typography>
              <Typography variant="body1">
                <strong>Pants Quantity:</strong> {selectedUniform?.pants_quantity} (Size: {selectedUniform?.pants_size})
              </Typography>
              <Typography variant="body1">
                <strong>Socks Quantity:</strong> {selectedUniform?.socks_quantity} (Size: {selectedUniform?.socks_size})
              </Typography>

              {/* Payment Method Selection */}
              <TextField
                select
                label="Select Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                fullWidth
                margin="normal"
              >
                <MenuItem value="מזומן">מזומן</MenuItem>
                <MenuItem value="אשראי">אשראי</MenuItem>
                <MenuItem value="ביט">ביט</MenuItem>
                <MenuItem value="פייבוקס">פייבוקס</MenuItem>
                <MenuItem value="שיק">שיק</MenuItem>
                <MenuItem value="העברה בנקאית">העברה בנקאית</MenuItem>
                <MenuItem value="אחר">אחר</MenuItem>
              </TextField>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button onClick={handlePayment} color="primary" disabled={!paymentMethod}>
                Pay
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Container>

  );
}

export default PayUniform;
