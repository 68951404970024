import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom';
import { formatTime, formatDate, getTrainingType } from '../utils';
import { useTheme } from '@mui/material/styles'; // Import useTheme

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const AdminActiveTrainings = ({ adminId, proId, token, showAll }) => {
  const [activeTrainings, setActiveTrainings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    const fetchActiveTrainings = async () => {
      try {
        let response;
        const endpoint = adminId
          ? 'api/active_trainings'
          : proId
          ? 'api/pro_active_trainings'
          : null;

        if (endpoint) {
          response = await fetch(process.env.REACT_APP_BASE_URL + endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...(adminId ? { admin_id: adminId } : { pro_id: proId }), token }),
          });

          if (!response.ok) {
            adminId ? navigate('/admin'): navigate('/pro');
          }

          const data = await response.json();
          if (data.success) {
            if (isDebugMode) console.log('data:', data);
            setActiveTrainings(data.active_trainings || []);
          } else {
            adminId ? navigate('/admin'): navigate('/pro');
          }
        } else {
          adminId ? navigate('/admin'): navigate('/pro');
        }
      } catch (error) {
        setError(`An error occurred: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveTrainings();
  }, [adminId, proId, token]);

  const handleRowClick = (training) => {
    if (proId) {
        navigate('/training_details', { state: { adminId, proId, token, trainingId: training.id } });
    } else {
        navigate('/training_details', { state: { adminId, token, trainingId: training.id } });
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  // Filter trainings based on showAll
  const filteredTrainings = showAll
    ? activeTrainings
    : activeTrainings.filter(training => [0, 1, 2].includes(training.training_type));

  const sortedTrainings = [...filteredTrainings].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortConfig.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortConfig.key === 'date') {
      return sortConfig.direction === 'asc'
        ? new Date(aValue) - new Date(bValue)
        : new Date(bValue) - new Date(aValue);
    } else if (sortConfig.key === 'time') {
      return sortConfig.direction === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    } else if (sortConfig.key === 'id') {
      return sortConfig.direction === 'asc'
        ? aValue - bValue
        : bValue - aValue;
    } else {
      return 0; // No sorting applied for unknown key
    }
  });

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        <CircularProgress />
        <Typography variant="h6" sx={{ ml: 2 }}>Loading active trainings...</Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

return (
  <TableContainer component={Paper} sx={{ backgroundColor: theme.palette.buttonBackground.main }}>
    <Table>
      <TableHead>
        <TableRow>

          {/*
            <TableCell
                onClick={() => handleSort('id')}
                sx={{
                  textAlign: 'right',
                  color: theme.palette.buttonBackground.contrastText,
                  borderColor: theme.palette.primary.main,
                }}
              >
                ID {sortConfig.key === 'id' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </TableCell>
          */}


          <TableCell
            onClick={() => handleSort('date')}
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            תאריך {sortConfig.key === 'date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
          </TableCell>

          <TableCell
            onClick={() => handleSort('time')}
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            שעה {sortConfig.key === 'time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
          </TableCell>

          <TableCell
            onClick={() => handleSort('training_type')}
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            סוג האימון {sortConfig.key === 'training_type' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
          </TableCell>

          <TableCell
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            אישרו
          </TableCell>


          {/*
              <TableCell
                sx={{
                  textAlign: 'right',
                  color: theme.palette.buttonBackground.contrastText,
                  borderColor: theme.palette.primary.main,
                }}
              >
                מיקום
              </TableCell>
          */}


          <TableCell
            onClick={() => handleSort('trainer')}
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            מאמנים {sortConfig.key === 'trainer' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
          </TableCell>

          {/*
              <TableCell
                onClick={() => handleSort('description')}
                sx={{
                  textAlign: 'right',
                  color: theme.palette.buttonBackground.contrastText,
                  borderColor: theme.palette.primary.main,
                }}
              >
                תיאור {sortConfig.key === 'description' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
              </TableCell>
          */}




           <TableCell
            onClick={() => handleSort('groups')}
            sx={{
              textAlign: 'right',
              color: theme.palette.buttonBackground.contrastText,
              borderColor: theme.palette.primary.main,
            }}
          >
            קבוצת אימון {sortConfig.key === 'groups' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
          </TableCell>



        </TableRow>
      </TableHead>
      <TableBody>
        {sortedTrainings.map((training) => (
          <TableRow
            key={training.id}
            onClick={() => handleRowClick(training)}
              sx={{
                cursor: 'pointer',
                backgroundColor: training.commited === 2 ? 'inherit': theme.palette.secondary.main,

              }}
          >
            {/*<TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>{training.id}</TableCell>*/}
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>
              {formatDate(training.date)}
            </TableCell>
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>
              {formatTime(training.time)}
            </TableCell>
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>
              {getTrainingType(training.training_type)}
            </TableCell>
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>
              {training.confirmed_trainees}/{training.max_trainees}
            </TableCell>
            {/*<TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>{training.location}</TableCell>*/}
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>
              {training.trainers}
            </TableCell>
            {/*<TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>{training.description || 'N/A'}</TableCell>*/}
            <TableCell sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText,borderColor: theme.palette.primary.main, }}>{training.group_name || 'N/A'}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);



};

export default AdminActiveTrainings;
