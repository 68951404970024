import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Checkbox, FormControlLabel } from '@mui/material';
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const EditTraineeDialog = ({ open, onClose, traineeDetails, adminId, token }) => {
  // Set initial state with current trainee details
  const [name, setName] = useState(traineeDetails.name || '');
  const [team, setTeam] = useState(traineeDetails.team || '');
  const [year, setYear] = useState(traineeDetails.year || '');
    const [groups, setGroups] = useState(
      (traineeDetails && traineeDetails.groups ? traineeDetails.groups.map(group => group.group_id) : []) || []
    );
  const [phone, setPhone] = useState(traineeDetails.phone || '');
  const [description, setDescription] = useState(traineeDetails.description || '');
  const [allGroups, setAllGroups] = useState([]);

  // Fetch all groups when dialog is opened
  useEffect(() => {
    if (open) {
      const fetchGroups = async () => {
        const fetchedGroups = await getTrainingGroups(adminId, token);
        setAllGroups(fetchedGroups);
      };
      fetchGroups();
    }
  }, [open, adminId, token]);

  // Update state when dialog is opened or trainee details are updated
  useEffect(() => {
    if (open) {
      setName(traineeDetails.name || '');
      setPhone(traineeDetails.phone || '');
      setTeam(traineeDetails.team || '');
      setYear(traineeDetails.year || '');
      setGroups(traineeDetails.groups.map(group => group.group_id) || []); // Store only group_ids
      setDescription(traineeDetails.description || '');
    }
  }, [open, traineeDetails]);

  // Fetch groups from API
  const getTrainingGroups = async (adminID, token) => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_training_groups', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: adminID, token: token }),
      });
      const jsonResponse = await response.json();
      if (isDebugMode) console.log(jsonResponse);
      if (jsonResponse.success) {
        return jsonResponse.groups || [];
      } else {
        if (isDebugMode) console.error("Error fetching training groups");
      }
    } catch (error) {
      if (isDebugMode) console.error('Error:', error);
    }
    return [];
  };

  // Handle checkbox change
  const handleGroupChange = (groupId) => {
    setGroups((prevGroups) =>
      prevGroups.includes(groupId)
        ? prevGroups.filter((id) => id !== groupId) // Unselect if already selected
        : [...prevGroups, groupId] // Select if not selected
    );
  };

  // Save the updated trainee details
  const handleSave = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/edit_trainee', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          admin_id: adminId,
          token,
          trainee_id: traineeDetails.id,
          name,
          team,
          groups, // Send selected groups here (group_ids)
          year,
          phone,
          description,
        }),
      });

      const data = await response.json();
      if (data.success) {
        // Notify parent component about the update
        onClose(true, adminId, token, traineeDetails.id);
      } else {
        // Optionally show error message to trainee
      }
    } catch (error) {
      // Optionally show error message to trainee
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>עריכת פרטי משתמש</DialogTitle>
      <DialogContent>
        <TextField
          label="שם"
          variant="outlined"
          fullWidth
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          label="טלפון"
          variant="outlined"
          fullWidth
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <TextField
          label="קבוצה"
          variant="outlined"
          fullWidth
          margin="normal"
          value={team}
          onChange={(e) => setTeam(e.target.value)}
        />
        <TextField
          label="שנתון"
          variant="outlined"
          fullWidth
          margin="normal"
          value={year}
          onChange={(e) => setYear(e.target.value)}
        />

        <div>
          <h3>בחר קבוצות</h3>
          {allGroups.map((group) => (
            <FormControlLabel
              key={group.id}
              control={
                <Checkbox
                  checked={groups.includes(group.id)} // Check if group_id is in the selected groups array
                  onChange={() => handleGroupChange(group.id)}
                  name={group.label}
                />
              }
              label={group.label}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>ביטול</Button>
        <Button onClick={handleSave}>שמור</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTraineeDialog;
