import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg'; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getTrainingGroups = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/get_training_groups', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.groups || [];
    } else {
      if (isDebugMode) console.error("Error fetching training groups");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

const updateOrDeleteGroup = async (adminId, token, groupId, groupName, defaultYears, isDelete, isAdd) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/add_edit_group_training', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        admin_id: adminId,
        token: token,
        delete: isDelete,
        add: isAdd,
        group_id: groupId,
        group_name: groupName,
        default_years: defaultYears,
      }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    return jsonResponse.success;
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return false;
};

function TrainingGroups() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [groups, setGroups] = useState([]);
  const [editingGroup, setEditingGroup] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const theme = useTheme(); // Access the theme for color adjustments
  const [newGroupName, setNewGroupName] = useState('');
  const [newGroupDefaultYears, setNewGroupDefaultYears] = useState('');

  const fetchData = async () => {
    const trainingGroups = await getTrainingGroups(adminId, token);
    setGroups(trainingGroups);
  };

  useEffect(() => {
    fetchData();
  }, [adminId, token]);

  const handleEdit = (group) => {
    setEditingGroup({
      id: group.id,
      label: group.label,
      defaultYears: group.years || [], // Initialize as empty array if undefined
    });
  };

  const handleDeleteClick = (group) => {
    setSelectedGroup(group);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedGroup(null);
  };

  const handleSave = async () => {
    if (editingGroup) {
      const success = await updateOrDeleteGroup(
        adminId,
        token,
        editingGroup.id,
        editingGroup.label,
        editingGroup.defaultYears,
        false,
        false,
      );
      if (success) fetchData();
    }
    setEditingGroup(null);
  };

  const handleDeleteConfirm = async () => {
    if (selectedGroup) {
      const success = await updateOrDeleteGroup(adminId, token, selectedGroup.id, selectedGroup.label, null, true, false);
      if (success) fetchData();
    }
    handleDialogClose();
  };

  const handleAddGroup = async () => {
    const defaultYearsArray = newGroupDefaultYears.split(',').map((year) => year.trim());
    const success = await updateOrDeleteGroup(adminId, token, null, newGroupName, defaultYearsArray, false, true);
    if (success) {
      setNewGroupName('');
      setNewGroupDefaultYears('');
      fetchData();
    }
  };

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}
          alt="Logo"
          src={myLogo}
        />
        <Typography component="h1" variant="h5" sx={{ color: theme.palette.dialogBackground2.contrastText }}>
          קבוצות אימון
        </Typography>

        <Box sx={{ width: '80%', marginTop: 4 }}>
          {/* Form to add new group */}
          <Box sx={{ marginBottom: 4 }}>
            <TextField
              label="שם קבוצה חדשה"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              fullWidth
              InputProps={{
                style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText }, // תווית בלבן
              }}
              sx={{
                 direction: 'ltr',
                '& .MuiOutlinedInput-root': {
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                    '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    borderBottom: `4px solid ${theme.palette.secondary.main}`,
                  },
                  backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
                },
                '& .MuiFormHelperText-root': {
                  border: 'none',
                },
              }}

            />
            <TextField
              label="שנתונים מופרד בפסיקים (1999,2000)"
              value={newGroupDefaultYears}
              onChange={(e) => setNewGroupDefaultYears(e.target.value)}
              fullWidth
              InputProps={{
            style: { color: theme.palette.primary.contrastText }, // טקסט של המשתמש בלבן
          }}
          InputLabelProps={{
            style: { color: theme.palette.primary.contrastText }, // תווית בלבן
          }}
          sx={{
             direction: 'ltr',
             marginTop: 3,
            '& .MuiOutlinedInput-root': {
                borderBottom: `2px solid ${theme.palette.primary.main}`,
                '& fieldset': {
                border: 'none',
              },
              '&:hover fieldset': {
                border: 'none',
              },
              '&.Mui-focused fieldset': {
                borderBottom: `4px solid ${theme.palette.secondary.main}`,
              },
              backgroundColor: theme.palette.buttonBackground.main,  // Add background color here
            },
            '& .MuiFormHelperText-root': {
              border: 'none',
            },
          }}

            />
            <Button
              variant="contained"
              sx={{ bgcolor: theme.palette.primary.main, marginTop: 2 }}
              onClick={handleAddGroup}
            >
              הוספת קבוצה
            </Button>
          </Box>

          {/* Display existing groups */}
          {groups.length > 0 ? (
            groups.map((group) => (
              <Box
                key={group.id}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: 2,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <Typography sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                    {group.label}
                  </Typography>
<Box sx={{ marginTop: 1 }}>
  {group.years && group.years.length > 0 ? (
    group.years.map((year, index) => (
      <Typography key={index} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
        {year}
      </Typography>
    ))
  ) : (
    <Typography sx={{ color: theme.palette.dialogBackground2.contrastText }}>
      לא שוייך לשנתון
    </Typography>
  )}
</Box>
                </Box>

<Box sx={{ display: 'flex', gap: 2 }}>
  <Button
    variant="contained"
    sx={{
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.primary.main,
      paddingLeft: 0,
    }}
    onClick={() => handleEdit(group)}
  >
    ערוך
  </Button>
  <Button
    variant="contained"
    sx={{
      color: theme.palette.primary.contrastText,
      bgcolor: theme.palette.error.main,
    }}
    onClick={() => handleDeleteClick(group)}
  >
    מחק
  </Button>
</Box>
              </Box>
            ))
          ) : (
            <Typography sx={{ color: theme.palette.dialogBackground2.contrastText, textAlign: 'center', marginTop: 4 }}>
              No training groups available.
            </Typography>
          )}
        </Box>
      </Box>

      {/* Edit Dialog */}
      {editingGroup && (
        <Dialog open={true} onClose={() => setEditingGroup(null)}>
          <DialogTitle>Edit Group</DialogTitle>
          <DialogContent>
            <TextField
              label="שם קבוצה"
              value={editingGroup.label}
              onChange={(e) => setEditingGroup({ ...editingGroup, label: e.target.value })}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
            <TextField
              label="שנתונים מופרד בפסיקים"
              value={editingGroup.defaultYears.join(', ')}
              onChange={(e) =>
                setEditingGroup({
                  ...editingGroup,
                  defaultYears: e.target.value.split(',').map((year) => year.trim()),
                })
              }
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              שמור
            </Button>
            <Button onClick={() => setEditingGroup(null)} color="primary">
              ביטול
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>מחיקת קבוצה</DialogTitle>
        <DialogContent>
          <DialogContentText>
            האם אתה בטוח שברצוך למחוק את הקבוצה?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            ביטול
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            כן, מחק
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TrainingGroups;
