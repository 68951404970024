import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { getTrainingType } from '../utils'; // Adjust the path as necessary
const isDebugMode = process.env.REACT_APP_DEBUG === 'true';


const formatDateTime = (dateString) => {
  const date = new Date(dateString);

  // Extract hours, minutes, day, month, and year
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${hours}:${minutes} ${day}/${month}/${year}`;
};

const CardsRecord = ({ adminId, token, refreshKey }) => {
  const [activeCards, setActiveCards] = useState([]);
  const [archivedCards, setArchivedCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('');
  const [sortColumn, setSortColumn] = useState('creation_time');
  const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
  const navigate = useNavigate();
  const theme = useTheme(); // Using theme for styling

  useEffect(() => {
    const fetchCardsData = async () => {
      try {
        // Fetch active cards
        const responseActive = await fetch(process.env.REACT_APP_BASE_URL + 'api/active_cards', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ admin_id: adminId, token }),
        });

        const dataActive = await responseActive.json();
if (isDebugMode)         console.log('Active Cards Data:', dataActive); // Log the active cards data
        if (dataActive.success) {
          setActiveCards(dataActive.active_cards || []); // Ensure it's an array
        } else {
          setError('Failed to fetch active cards');
        }

        // Fetch archived cards
        const responseArchived = await fetch(process.env.REACT_APP_BASE_URL + 'api/archived_cards', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ admin_id: adminId, token }),
        });

        const dataArchived = await responseArchived.json();
if (isDebugMode)         console.log('Archived Cards Data:', dataArchived); // Log the archived cards data
        if (dataArchived.success) {
          setArchivedCards(dataArchived.archived_cards || []); // Ensure it's an array
        } else {
          setError('Failed to fetch archived cards');
        }
      } catch (error) {
if (isDebugMode)         console.error('Error fetching cards data:', error); // Log the error
        setError('An error occurred while fetching cards data');
      } finally {
        setLoading(false);
      }
    };

    fetchCardsData();
  }, [adminId, token, refreshKey]); // Include refreshKey as a dependency

  const handleRowClick = (card) => {
if (isDebugMode)     console.log('handleRowClick:', card); // Log the card data
    navigate('/card_details', { state: { adminId, token, cardId: card.id } });
  };

  // Filter function for name
  const filterCards = (cards = []) => {
    // Apply filter
    const filteredCards = cards.filter(card => card.name.toLowerCase().includes(filter.toLowerCase()));

    // Apply sorting
    return filteredCards.sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];
      let comparison = 0;

      if (valueA < valueB) comparison = -1;
      if (valueA > valueB) comparison = 1;

      return sortDirection === 'asc' ? comparison : -comparison;
    });
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('desc');
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{color: theme.palette.dialogBackground2.contrastText, padding: '16px' }}>
<TextField
  label="סנן לפי שם"
  variant="outlined"
  fullWidth
  value={filter}
  onChange={(e) => setFilter(e.target.value)}
  sx={{
    marginBottom: '16px',
    '& .MuiInputBase-root': {
      color: theme.palette.dialogBackground2.contrastText,  // Set text color
    },
    '& .MuiInputLabel-root': {
      color: theme.palette.dialogBackground2.contrastText,  // Set label color
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,  // Set border color
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,  // Set border color on hover
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,  // Set border color when focused
    },
  }}
/>


      <Typography variant="h6" sx={{ color: theme.palette.dialogBackground2.contrastText }}>
        כרטיסיות פעילות
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '16px', color: theme.palette.dialogBackground2.contrastText }}>
        {filter && <span>מסנן לפי: {filter}</span>}
      </Typography>
      <TableContainer component={Paper} sx={{ marginBottom: '16px', backgroundColor: 'transparent'}}>
        <Table>
          <TableHead sx={{borderBottom: `2px solid ${theme.palette.primary.main}`}}>
            <TableRow>
              <TableCell onClick={() => handleSort('creation_time')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                תאריך הפקה
                {sortColumn === 'creation_time' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('name')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                שם
                {sortColumn === 'name' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('training_type')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                סוג
                {sortColumn === 'training_type' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('price')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                מחיר
                {sortColumn === 'price' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('amount_paid')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                שולם
                {sortColumn === 'amount_paid' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('lessons_count')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                מספר אימונים
                {sortColumn === 'lessons_count' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('lessons_used')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                אימונים שנוצלו
                {sortColumn === 'lessons_used' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterCards(activeCards).map((card) => (
              <TableRow
                key={card.id}
                onClick={() => handleRowClick(card)}
                sx={{ cursor: 'pointer', color: theme.palette.dialogBackground2.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}
              >
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}> {formatDateTime(card.creation_time)}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.name}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{getTrainingType(card.training_type)}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.price}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.amount_paid}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.lessons_count}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.lessons_used}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ color: theme.palette.dialogBackground2.contrastText, marginTop: 20}}>
        היסטוריית כרטיסיות
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '16px', color: theme.palette.dialogBackground2.contrastText }}>
        {filter && <span>פילטר לפי שם: {filter}</span>}
      </Typography>
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
        <Table>
          <TableHead sx={{borderBottom: `2px solid ${theme.palette.primary.main}`}}>
            <TableRow>
              <TableCell onClick={() => handleSort('creation_time')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                תאריך הפקה
                {sortColumn === 'creation_time' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('name')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                שם
                {sortColumn === 'name' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('training_type')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                סוג
                {sortColumn === 'training_type' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('price')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                מחיר
                {sortColumn === 'price' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('amount_paid')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                שולם
                {sortColumn === 'amount_paid' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('lessons_count')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                מספר אימונים
                {sortColumn === 'lessons_count' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
              <TableCell onClick={() => handleSort('lessons_used')} sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                אימונים שנוצלו
                {sortColumn === 'lessons_used' && (sortDirection === 'asc' ? <ArrowUpwardIcon sx={{ color: theme.palette.primary.main }} /> : <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterCards(archivedCards).map((card) => (
              <TableRow
                key={card.id}
                onClick={() => handleRowClick(card)}
                sx={{ cursor: 'pointer', color: theme.palette.dialogBackground2.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}`}}
              >
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{formatDateTime(card.creation_time)}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.name}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{getTrainingType(card.training_type)}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.price}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.amount_paid}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.lessons_count}</TableCell>
                <TableCell sx={{ color: theme.palette.dialogBackground2.contrastText }}>{card.lessons_used}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CardsRecord;