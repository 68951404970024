import React, { useState, useEffect } from 'react';
import {
  TableRow, Table, TableHead, TableBody, TableCell,
  Typography, Container, Box, Paper, CircularProgress,
  Alert, styled, MenuItem,Checkbox, Button, Tabs, Tab, Grid, FormControlLabel, Dialog, DialogActions, DialogContent, DialogTitle, TextField
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme


const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const StyledTableContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  backgroundColor: theme.palette.buttonBackground.main,  // Background color for the container
}));

const DataTable = ({ title, columns, data, onCheckboxChange, isHistory, theme }) => (

  <Box sx={{ marginTop: 4 }}>
    <Typography variant="h6" sx={{ textAlign: 'right', color: theme.palette.buttonBackground.contrastText }}>
      {title}
    </Typography>
    <StyledTableContainer component={Paper}>
      <Table sx={{
        '& .MuiTableCell-root': {
          borderBottom: `1px solid ${theme.palette.primary.main}`, // Set the border color here
        },
      }}>
        <TableHead>
          <TableRow>
            {columns.map((col, index) => (
              <TableCell key={index}>
                <Typography sx={{ color: theme.palette.buttonBackground.contrastText }}>
                  {col}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item.id || index}>
              {Object.values(item).map((value, i) => (
                <TableCell key={i} sx={{ color: theme.palette.buttonBackground.contrastText }}>
                  {value}
                </TableCell>
              ))}
              {isHistory && (
                <TableCell sx={{ color: theme.palette.buttonBackground.contrastText }}>
                  {item.is_paid ? (
                    <Typography variant="body2" sx={{ color: theme.palette.buttonBackground.contrastText }}>
                      שולם
                    </Typography>
                  ) : (
                    <Checkbox
                      checked={item.isChecked}
                      onChange={(event) => {
                        if (isDebugMode) console.info("Item ID:", item.id);
                        onCheckboxChange(item.id, event.target.checked);
                      }}
                    />
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
  </Box>
);



const fetchDetails = async (url, payload) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  });
  return await response.json();
};

const AdminTrainerDetails = () => {
  const theme = useTheme(); // Using theme for styling
  console.log(theme); // Check the value of the theme

  const { adminId, token, proId } = useLocation().state || {};
  const [trainerDetails, setTrainerDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedTrainingTypes, setSelectedTrainingTypes] = useState([]);
  const [value, setValue] = useState(0);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [sessionPrices, setSessionPrices] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDescription, setPaymentDescription] = useState("");


  useEffect(() => {
    const fetchTrainerDetails = async () => {
      setLoading(true);
      try {
        const trainer = await fetchDetails(`${process.env.REACT_APP_BASE_URL}api/admin_trainer_details`, { admin_id: adminId, token, pro_id: proId });
        if (trainer.success) {
          setTrainerDetails(trainer.trainer_details || {});
          setSelectedTrainingTypes(trainer.trainer_details.professional_training_types || []);
        } else {
          setError(trainer.message);
        }
      } catch {
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };
    fetchTrainerDetails();
  }, [adminId, token, proId]);

  const handleCheckboxChange = (id, isChecked) => {
    setSelectedTrainingTypes(prev => isChecked ? [...prev, id] : prev.filter(type => type !== id));
  };

  const handleSessionCheckboxChange = (sessionId, isChecked) => {
if (isDebugMode)     console.info("sessionId", sessionId);
if (isDebugMode)     console.info("isChecked", isChecked);
    setSelectedSessions(prev => isChecked ? [...prev, sessionId] : prev.filter(id => id !== sessionId));
  };

  const updateTrainerTrainingTypes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/update_trainer_training_type`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ admin_id: adminId, token, pro_id: proId, training_types: selectedTrainingTypes }),
      });
      const jsonResponse = await response.json();
      if (!jsonResponse.success) {
if (isDebugMode)         console.error("Error updating training types:", jsonResponse.message);
      }
    } catch (error) {
if (isDebugMode)       console.error('Error:', error);
    }
  };

  const openPaymentDialog = () => {
    const initialPrices = selectedSessions.map(sessionId => {
      const session = trainerDetails.archived_training_sessions.find(s => s.id === sessionId);
      return {
        id: sessionId,
        price: session ? (session.is_paid ? session.pay : session.default_cost) : 0,
      };
    });
    setSessionPrices(initialPrices);
    setPaymentDialogOpen(true);
  };

  const handlePriceChange = (sessionId, newPrice) => {
    setSessionPrices(prev => prev.map(session => session.id === sessionId ? { ...session, price: newPrice } : session));
  };

  const calculateTotal = () => {
    return sessionPrices.reduce((total, session) => total + parseFloat(session.price || 0), 0).toFixed(2);
  };

  const payTrainer = async () => {
    const result = await fetchDetails(`${process.env.REACT_APP_BASE_URL}api/pay_trainer`, {
      admin_id: adminId,
      token,
      pro_id: proId,
      session_ids: selectedSessions,
      prices: sessionPrices,
      payment_method: paymentMethod,         // Add payment method
      payment_description: paymentDescription  // Add payment description
    });
    if (result.success) {
      // Handle success
      setPaymentDialogOpen(false);
    } else {
if (isDebugMode)       console.error("Error paying sessions:", result.message);
    }
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const { professional = {}, training_types = [], professional_training_types = [], active_training_sessions = [], archived_training_sessions = [] } = trainerDetails;

  const trainingTypeMap = training_types.reduce((acc, type) => {
    acc[type.id] = type.label;
    return acc;
  }, {});

return (
  <Container component="main" maxWidth="md">
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 0,
        backgroundColor: theme.palette.buttonBackground.main,
      }}
    >
      {/* DataTable for Professional Details */}
<DataTable
  theme={theme}
  columns={["שם", "אימייל", "טלפון", "סוגי אימון"]}
  data={[
    {
      name: professional.name || "",
      email: professional.email || "",
      phone: professional.phone || "",
      trainingTypes:
        training_types
          .filter((type) => professional_training_types.includes(type.id))
          .map((type) => type.label)
          .map((type, index) => (
            <Typography key={index} sx={{ display: 'block' }}>
              {type}
            </Typography>
          )) || <Typography sx={{ display: 'block' }}>לא הוזן</Typography>, // אם אין סוגי אימון
    },
  ]}
  isHistory={false}
/>

      {/* Types of Training Section */}
      <Box sx={{ marginTop: 4, width: "100%" }}>
        <Typography variant="h6"sx={{ color: theme.palette.dialogBackground2.contrastText }}>סוגי אימון</Typography>
        <Grid container spacing={2} justifyContent="flex-end">
          {training_types.map((type) => (
            <Grid item xs={4} key={type.id}>
              <FormControlLabel
                control={
                  <Checkbox sx={{ color: theme.palette.primary.main }}
                    checked={selectedTrainingTypes.includes(type.id)}
                    onChange={(event) =>
                      handleCheckboxChange(type.id, event.target.checked)
                    }
                    value={type.id}
                  />
                }
                label={
                <Typography sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                    {type.label}
                  </Typography>
                }
              />
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={updateTrainerTrainingTypes}
          sx={{ marginTop: 2 }}
        >
          עדכן סוגי אימון
        </Button>
      </Box>

      {/* Tabs for Active and Archived Training Sessions */}
      <Tabs
        value={value}
        onChange={(event, newValue) => setValue(newValue)}
        aria-label="trainer tabs"
      >
        <Tab label="אימונים פעילים" />
        <Tab label="היסטוריית אימונים" />
      </Tabs>

      {/* Active Training Sessions */}
      {value === 0 && active_training_sessions.length > 0 && (
        <DataTable
          theme={theme}

          columns={["תיאור", "מיקום", "מתאמנים", "סוג האימון", "שעה", "תאריך"]}
          data={active_training_sessions.map((session) => ({
            description: session.description || "לא הוזן",
            location: session.location || "לא הוזן",
            trainees:
              session.trainees.length > 0
                ? session.trainees
                    .map((trainee) => `${trainee.name} (${trainee.year})`)
                    .join(", ")
                : "לא הוזן",
            trainingType: trainingTypeMap[session.training_type] || "לא הוזן",
            time: new Date(session.time * 1000).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
            date: new Date(session.date).toLocaleDateString(),
          }))}
          isHistory={false}
        />
      )}

      {/* Archived Training Sessions */}
      {value === 1 && archived_training_sessions.length > 0 && (
        <>
          <DataTable
            theme={theme}

            columns={[
              "תיאור",
              "מיקום",
              "מתאמנים",
              "סוג האימון",
              "שעה",
              "תאריך",
              "מחיר",
            ]}
            data={archived_training_sessions.map((session) => ({
              description: session.description || "לא הוזן",
              location: session.location || "לא הוזן",
              trainees:
                session.trainees.length > 0
                  ? session.trainees
                      .map((trainee) => `${trainee.name} (${trainee.year})`)
                      .join(", ")
                  : "לא הוזן",
              trainingType: trainingTypeMap[session.training_type] || "לא הוזן",
              time: new Date(session.time * 1000).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              }),
              date: new Date(session.date).toLocaleDateString(),
              price: session.is_paid ? session.pay : session.default_cost,
              isChecked: selectedSessions.includes(session.id),
              is_paid: session.is_paid, // Include the is_paid property
              id: session.id, // Ensure id is included
            }))}
            onCheckboxChange={handleSessionCheckboxChange}
            isHistory={true}
          />
          <Box sx={{ marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={openPaymentDialog}
              disabled={selectedSessions.length === 0}
            >
              {selectedSessions.length === 0 ? "בצע תשלום" : "בצע תשלום"}
            </Button>
          </Box>
        </>
      )}

      {/* Payment Dialog */}
      <Dialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>תשלום למאמן</DialogTitle>
        <DialogContent>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            אימונים לביצוע תשלום
          </Typography>

          <DataTable
            theme={theme}

            title=""
            columns={["תיאור", "סוג האימון", "מחיר"]}
            data={sessionPrices.map((session) => {
              if (isDebugMode) console.log("Current Session:", session); // Log the current session being processed

              const foundSession = archived_training_sessions.find(
                (s) => s.id === session.id
              );
              if (isDebugMode) console.log("Session ID:", session.id);
              if (isDebugMode) console.log("Found Session:", foundSession);

              return {
                description: foundSession
                  ? foundSession.description
                  : "לא הוזן",
                type: foundSession
                  ? trainingTypeMap[foundSession.training_type]
                  : "לא הוזן",
                price: (
                  <TextField
                    type="number"
                    value={session.price}
                    onChange={(e) =>
                      handlePriceChange(session.id, e.target.value)
                    }
                    variant="outlined"
                    fullWidth
                    margin="dense"
                  />
                ),
              };
            })}
          />

          <TextField
            label="תיאור תשלום"
            variant="outlined"
            fullWidth
            margin="dense"
            multiline
            rows={2}
            placeholder="הוסף תיאור לתשלום"
            value={paymentDescription} // Bind to state
            onChange={(e) => setPaymentDescription(e.target.value)} // Update state
          />

          <TextField
            select
            label="אופן תשלום"
            variant="outlined"
            fullWidth
            margin="dense"
            value={paymentMethod} // Bind to state
            onChange={(e) => setPaymentMethod(e.target.value)} // Update state
          >
            <MenuItem value="מזומן">מזומן</MenuItem>
            <MenuItem value="אשראי">אשראי</MenuItem>
            <MenuItem value="ביט">ביט</MenuItem>
            <MenuItem value="פייבוקס">פייבוקס</MenuItem>
            <MenuItem value="שיק">שיק</MenuItem>
            <MenuItem value="העברה בנקאית">העברה בנקאית</MenuItem>
            <MenuItem value="אחר">אחר</MenuItem>
          </TextField>

          <Typography variant="h6" sx={{ marginTop: 2 }}>
            סה"כ לתשלום: {calculateTotal()} ש"ח
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPaymentDialogOpen(false)}>בטל</Button>
          <Button onClick={payTrainer} variant="contained" color="primary">
            אשר תשלום
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  </Container>
);


};

export default AdminTrainerDetails;
