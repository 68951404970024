import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles'; // Import useTheme
import { useLocation as useRouterLocation } from 'react-router-dom';
import PieChartSection from './PieChartSection';
import ActiveTrainings from './ActiveTrainings';
import NavBar from '../NavBar'; // Import the NavBar component

import myLogo from '../../ez.jpeg';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

function Dashboard() {
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {};
  const [tabIndex, setTabIndex] = React.useState(0); // Initialize the tab index
  const theme = useTheme();

  React.useEffect(() => {
    if (isDebugMode) console.log('User ID:', userId);
    if (isDebugMode) console.log('Token:', token);
  }, [userId, token]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Main content area */}
      <Container component="main" maxWidth="md" className="users-page" sx={{ flex: 1, overflowY: 'auto', paddingBottom: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', marginTop: 8}}>
          <Avatar
            sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}
            alt="Logo"
            src={myLogo}
          />

          {/* PieChart Section */}
          <Box sx={{ mb: 2, width: '100%', maxWidth: 600 , marginTop: 5}}>
            <PieChartSection />
          </Box>

          {/* Active Trainings Section */}
          <Box sx={{ mb: 2, width: '100%', maxWidth: 600, paddingBottom: 5}}>
            <ActiveTrainings enableScroll={true} />
          </Box>
        </Box>
      </Container>

      {/* NavBar Component */}
      <NavBar userId={userId} token={token} tabIndex={tabIndex} setTabIndex={setTabIndex} sx={{ position: 'fixed', bottom: 0, width: '100%' }} />
    </Box>
  );
}

export default Dashboard;
