import * as React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; // Import useTheme

export default function CheckEmail() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5" sx={{ marginTop: 8,color: theme.palette.primary.contrastText }}>
          Check Your Email
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ marginTop: 8,color: theme.palette.primary.contrastText }}>
נשלח מייל אימות, בדוק את תיבת הדואר שלך
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/first_signin')}
          >
            מעבר לכניסה
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
