import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles'; // Import useTheme for access to theme

const AddTrainerDialog = ({ open, onClose, onAddTrainer }) => {
    const [newTrainer, setNewTrainer] = useState({ name: '', phone: '', team: '', year: '' });
    const [agreement1, setAgreement1] = useState(false);
    const [agreement2, setAgreement2] = useState(false);
    const [agreement3, setAgreement3] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [currentTerm, setCurrentTerm] = useState('');
    const [errors, setErrors] = useState({});
    const theme = useTheme(); // Access the theme for color adjustments

    const validateInputs = () => {
        const newErrors = {};
        const currentYear = new Date().getFullYear();
        const minYear = currentYear - 100; // מינימום 100 שנה אחורה

        // בדיקות עבור השם
        if (!newTrainer.name) {
            newErrors.name = 'ערך שם לא חוקי';
        } else if (!/^[\u0590-\u05FF\s]+$/.test(newTrainer.name)) {
            newErrors.name = 'שם חייב להיות באותיות עבריות';
        }

        // בדיקות עבור הטלפון
        if (newTrainer.phone && !/^\d{10}$/.test(newTrainer.phone)) { // לדוגמה, מספר טלפון עם 10 ספרות
            newErrors.phone = 'ערך טלפון לא חוקי';
        }

        // בדיקות עבור הקבוצה
        if (!newTrainer.team) {
            newErrors.team = 'ערך קבוצה לא חוקי';
        } else if (!/^[\u0590-\u05FF\s\u0022\u0027]+$/.test(newTrainer.team)) { // תווים עבריים + מרכאות/גרש
            newErrors.team = 'קבוצה חייבת להיות באותיות עבריות עם תווים תקינים';
        }

        // בדיקות עבור שנתון
        if (!newTrainer.year) {
            newErrors.year = 'ערך שנתון לא חוקי';
        } else if (!/^\d{4}$/.test(newTrainer.year) || newTrainer.year < minYear || newTrainer.year > currentYear) {
            newErrors.year = 'שנתון חייב להיות בשנים הגיוניות (נניח 1920-2023)';
        }

        return Object.keys(newErrors).length === 0 ? true : newErrors;
    };

    const handleAddTrainer = () => {
        const validation = validateInputs();
        if (!agreement1 || !agreement2 || !agreement3) {
            alert('יש לאשר את כל תנאי השימוש');
            return false;
        }
        if (validation === true) {
            console.log('Adding trainer:', newTrainer);
            onAddTrainer(newTrainer);
            setNewTrainer({ name: '', phone: '', team: '', year: '' });
            setAgreement1(false);
            setAgreement2(false);
            setAgreement3(false);
            setErrors({});
            onClose(); // סגור את הדיאלוג
        } else {
            setErrors(validation);
        }
    };

    const handleTermClick = (term) => {
        setCurrentTerm(term);
        setDialogOpen(true);
    };

    const closeDialog = () => {
        setDialogOpen(false);
        setCurrentTerm('');
    };

    const termDescriptions = {
        agreement1: "הנני מאשר כי אני מודע לכך שהמידע והתכנים הנוגעים לפעילותי עשויים להצטלם ולהתפרסם במדיה. אני מבין כי תכנים אלו עשויים לכלול תמונות, סרטונים וחומרים נוספים אשר ייחשפו לקהל הרחב. אני מסכים במפורש כי EZ academy רשאי להשתמש בתכנים אלו לצורכי פרסום ושיווק ללא צורך באישור נוסף מצידי או בתשלום עבור השימוש בתכנים.",
        agreement2: "הנני מאשר כי אני מודע לכך שעליי לספק אישור רפואי עדכני, המאשר את כשירותי לבצע את הפעילויות המפורטות על ידי EZ academy. אני מתחייב להציג אישור זה לפני תחילת הפעילויות ואני לוקח על עצמי את כל האחריות למידע הנמסר. אני מבין כי אם לא אציג אישור רפואי תקף, לא אוכל להשתתף בפעילויות המוצעות על ידי EZ academy.",
        agreement3: "הנני מאשר כי אני מבין ומסכים שהמידע, התכנים והחומרים המועלים על ידי EZ academy הינם קניין רוחני של EZ academy או צדדים שלישיים. אני מתחייב שלא להעתיק, לשכפל, להפיץ או לנצל כל תוכן או חומר שנמסרו לי על ידי EZ academy ללא אישור בכתב מראש. כל הפרה של התחייבות זו תגרור צעדים משפטיים בהתאם לחוק."
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                dir="rtl"
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.dialogBackground2.main, // רקע שקוף
                    }
                }}
            >
                <DialogTitle sx={{ color: theme.palette.dialogBackground2.contrastText }}>הוספת מתאמן חדש</DialogTitle>
                <DialogContent sx={{ maxHeight: '400px', overflowY: 'auto', color: theme.palette.dialogBackground2.contrastText }}>

                    <TextField
                        autoFocus
                        variant="standard"
                        margin="dense"
                        label="שם מלא"
                        type="text"
                        fullWidth
                        value={newTrainer.name}
                        onChange={(e) => {
                            setNewTrainer({ ...newTrainer, name: e.target.value });
                            validateInputs(); // בדוק את הקלט בכל שינוי
                        }}
                        error={!!errors.name}
                        helperText={errors.name}
                        InputProps={{
                            style: {
                                textAlign: 'right',
                                borderColor: errors.name ? 'red' : theme.palette.primary.main,
                                color: theme.palette.dialogBackground2.contrastText, // טקסט תואם לצבע הרקע
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                direction: 'ltr',
                                textAlign: 'left',
                                color: errors.name ? 'red' : theme.palette.dialogBackground2.contrastText, // צבע תגית מותאם
                            },
                        }}
                        sx={{
                            color: theme.palette.dialogBackground2.contrastText,
                            '& .MuiInput-underline:before': {
                                borderBottomColor: errors.name ? 'red' : theme.palette.primary.main, // צבע גבול תחתון
                            },
                            '& .MuiInput-underline:after': {
                                borderBottomColor: theme.palette.primary.main, // צבע גבול תחתון במיקוד
                            },
                            '& .MuiFormHelperText-root': {
                                textAlign: 'right', // טקסט עזרה מיושר לימין
                                color: errors.name ? 'red' : theme.palette.secondary.contrastText, // צבע טקסט עזרה
                            },
                        }}
                    />

                    <TextField
                        margin="dense"
                        label="טלפון (ניתן להשאיר ריק)"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newTrainer.phone}
                        onChange={(e) => {
                            setNewTrainer({ ...newTrainer, phone: e.target.value });
                            validateInputs();
                        }}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        InputProps={{
                            style: {
                                textAlign: 'right',
                                borderColor: errors.phone ? 'red' : theme.palette.primary.main,
                                color: theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                direction: 'rtl',
                                textAlign: 'right',
                                color: errors.phone ? 'red' : theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                    />

                    <TextField
                        margin="dense"
                        label="קבוצה"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newTrainer.team}
                        onChange={(e) => {
                            setNewTrainer({ ...newTrainer, team: e.target.value });
                            validateInputs();
                        }}
                        error={!!errors.team}
                        helperText={errors.team}
                        InputProps={{
                            style: {
                                textAlign: 'right',
                                borderColor: errors.team ? 'red' : theme.palette.primary.main,
                                color: theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                direction: 'rtl',
                                textAlign: 'right',
                                color: errors.team ? 'red' : theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                    />

                    <TextField
                        margin="dense"
                        label="שנתון"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={newTrainer.year}
                        onChange={(e) => {
                            setNewTrainer({ ...newTrainer, year: e.target.value });
                            validateInputs();
                        }}
                        error={!!errors.year}
                        helperText={errors.year}
                        InputProps={{
                            style: {
                                textAlign: 'right',
                                borderColor: errors.year ? 'red' : theme.palette.primary.main,
                                color: theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                        InputLabelProps={{
                            style: {
                                direction: 'rtl',
                                textAlign: 'right',
                                color: errors.year ? 'red' : theme.palette.dialogBackground2.contrastText,
                            },
                        }}
                    />

<FormControlLabel
    control={
        <Checkbox
            checked={agreement1}
            onChange={(e) => setAgreement1(e.target.checked)}
            color="primary"
        />
    }
    label={
        <Typography variant="body2" style={{ color: theme.palette.dialogBackground2.contrastText }}>
            הנני מאשר כי אני מודע לכך שהמידע והתכנים הנוגעים לפעילותי עשויים להצטלם ולהתפרסם במדיה. אני מבין כי תכנים אלו עשויים לכלול תמונות, סרטונים וחומרים נוספים אשר ייחשפו לקהל הרחב. אני מסכים במפורש כי EZ academy רשאי להשתמש בתכנים אלו לצורכי פרסום ושיווק ללא צורך באישור נוסף מצידי או בתשלום עבור השימוש בתכנים.
        </Typography>
    }
/>

<FormControlLabel
    control={
        <Checkbox
            checked={agreement2}
            onChange={(e) => setAgreement2(e.target.checked)}
            color="primary"
        />
    }
    label={
        <Typography variant="body2" style={{ color: theme.palette.dialogBackground2.contrastText }}>
            הנני מאשר כי אני מודע לכך שעליי לספק אישור רפואי עדכני, המאשר את כשירותי לבצע את הפעילויות המפורטות על ידי EZ academy. אני מתחייב להציג אישור זה לפני תחילת הפעילויות ואני לוקח על עצמי את כל האחריות למידע הנמסר. אני מבין כי אם לא אציג אישור רפואי תקף, לא אוכל להשתתף בפעילויות המוצעות על ידי EZ academy.
        </Typography>
    }
/>

<FormControlLabel
    control={
        <Checkbox
            checked={agreement3}
            onChange={(e) => setAgreement3(e.target.checked)}
            color="primary"
        />
    }
    label={
        <Typography variant="body2" style={{ color: theme.palette.dialogBackground2.contrastText }}>
            הנני מאשר כי אני מבין ומסכים שהמידע, התכנים והחומרים המועלים על ידי EZ academy הינם קניין רוחני של EZ academy או צדדים שלישיים. אני מתחייב שלא להעתיק, לשכפל, להפיץ או לנצל כל תוכן או חומר שנמסרו לי על ידי EZ academy ללא אישור בכתב מראש. כל הפרה של התחייבות זו תגרור צעדים משפטיים בהתאם לחוק.
        </Typography>
    }
/>


                </DialogContent>
                <DialogActions sx={{ color: theme.palette.dialogBackground2.contrastText }}>
                    <Button onClick={onClose}>ביטול</Button>
                    <Button onClick={handleAddTrainer}>הוסף</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddTrainerDialog;
