import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './components/LoginPage';
import UserDashboard from './components/user/UserDashboard';
import Training from './components/user/Training';
import Cards from './components/user/Cards';
import UserInfo from './components/user/UserInfo';

import AdminLogin from './components/AdminLogin';
import AdminTrainings from './components/admin/AdminTrainings';
import AddTraining from './components/admin/AddTraining'; // Import the new component
import TrainingDetails from './components/admin/TrainingDetails'; // Import the new component
import AdminCards from './components/admin/AdminCards'; // Import the new component
import AdminUsersInfo from './components/admin/AdminUsersInfo'; // Import the new component
import AdminUserDetails from './components/admin/AdminUserDetails'; // Import the new component
import AdminTrainerDetails from './components/admin/AdminTrainerDetails'; // Import the new component
import SignUp from './components/user/SignUp'; // Import the new component
import CheckEmail from './components/CheckEmail'; // Import the new component
import FirstSignIn from './components/user/FirstSignIn'; // Import the new component
import SignUpAdmin from './components/admin/SignUpAdmin'; // Import the new component
import FirstLoginAdmin from './components/admin/FirstLoginAdmin'; // Import the new component
import CardDetails from './components/admin/AdminCardDetails'; // Import the new component
import UserCardDetails from './components/user/UserCardDetails'; // Import the new component
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/assistant'; // Defaults to weight 400
import '@fontsource/assistant/700.css'; // Weight 700
import './App.css'; // Import your CSS file
//pro
import ProTrainings from './components/professionals/ProTrainings';
import ProLogin from './components/professionals/ProLogin';
import SignUpPro from './components/professionals/SignUpPro'; // Import the new component
import FirstLoginPro from './components/professionals/FirstLoginPro'; // Import the new component
import ProInfo from './components/admin/ProInfo'; // Import the new component
import Management from './components/admin/Management'; // Import the new component
import PayUniform from './components/admin/PayUniform'; // Import the new component
import TrainingsNoCard from './components/admin/TrainingsNoCard'; // Import the new component
import TrainingGroups from './components/admin/TrainingGroups'; // Import the new component
import TraineesCards from './components/admin/TraineesCards'; // Import the new component
const defaultTheme = createTheme({
 palette: {
    primary: {
      main: '#a64729', // 200
      ['main-400']: '#a64729',
      contrastText: '#fff',
    },
    secondary: {
      main: '#573322',
      contrastText: '#000',
    },

    buttonBackground: {
        main: 'rgba(44, 44, 44, 0.4)',  // הוספת שקיפות
        contrastText: '#D3D3D3',
    },

    dialogBackground: {
        main: 'rgba(44, 44, 44, 0.2)',  // הוספת שקיפות
      contrastText: '#D3D3D3',
    },


    dialogBackground2: {
      main: '#1e1e1e',  // Using an image URL for background
      contrastText: '#fff',
    },

    navBarBackground: {
        main: 'rgba(44, 44, 44, 1)',  // הוספת שקיפות
      contrastText: '#D3D3D3',
    },

    pie_primary: {
      main: 'rgba(44, 44, 44, 0.4)',  // צבע אדום
      contrastText: '#ffffff',  // צבע טקסט
    },
    pie_secondary: {
      main: 'rgba(166, 71, 41, 0.6)',  // צבע ירוק
      contrastText: '#ffffff',  // צבע טקסט
    },
    gradients: {
      primary: 'linear-gradient(45deg, #2ecc71, #27ae60)', // גרדיאנט ירוק
      secondary: 'linear-gradient(45deg, #e74c3c, #c0392b)', // גרדיאנט אדום
    },
  },
  typography: {
    fontFamily: "'Assistant', sans-serif",
  },
});

function App() {
  return (
    <Router>
  <ThemeProvider theme={defaultTheme} >
      <div className="App">
        <Routes>
          <Route path="/signUp" element={<SignUp />} />//register new user
          <Route path="/first_signin" element={<FirstSignIn />} />
          <Route path="/userinfo" element={<UserInfo />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/trainings" element={<Training />} />
          <Route path="/cards" element={<Cards />} />
          <Route path="/admin" element={<AdminLogin />} />
          <Route path="/admin_trainings" element={<AdminTrainings />} />
          <Route path="/add_training" element={<AddTraining />} />
          <Route path="/training_details" element={<TrainingDetails />} />
          <Route path="/admin_cards" element={<AdminCards />} />
          <Route path="/admin_user_details" element={<AdminUserDetails />} />
          <Route path="/admin_trainer_details" element={<AdminTrainerDetails />} />
          <Route path="/users_info" element={<AdminUsersInfo />} />
          <Route path="/check_email" element={<CheckEmail />} />
          <Route path="/signup_admin" element={<SignUpAdmin />} />
          <Route path="/first_login_admin" element={<FirstLoginAdmin />} />
          <Route path="/card_details" element={<CardDetails />} />
          <Route path="/user_card_details" element={<UserCardDetails />} />
          <Route path="/signup_pro" element={<SignUpPro />} />
          <Route path="/pro" element={<ProLogin />} />
          <Route path="/first_login_pro" element={<FirstLoginPro />} />
          <Route path="/pro_trainings" element={<ProTrainings />} />
          <Route path="/pro_info" element={<ProInfo />} />
          <Route path="/management" element={<Management />} />
          <Route path="/pay_uniform" element={<PayUniform />} />
          <Route path="/training_no_card" element={<TrainingsNoCard />} />
          <Route path="/training_groups" element={<TrainingGroups />} />
          <Route path="/trainees_cards" element={<TraineesCards />} />

        </Routes>
      </div>
    </ThemeProvider>
    </Router>
  );
}
export default App;
