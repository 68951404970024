import React, { useState, useEffect } from 'react';
import { IconButton, TextField, Box, Container, Avatar, Typography, Dialog, DialogActions, DialogContent, DialogTitle, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CardIcon from '@mui/icons-material/CreditCard';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useLocation as useRouterLocation, useNavigate } from 'react-router-dom';
import myLogo from '../../ez.jpeg';

const isDebugMode = process.env.REACT_APP_DEBUG === 'true';

const getTrainees = async (adminID, token) => {
  try {
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/trainees', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ admin_id: adminID, token: token }),
    });
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.trainees || [];
    } else {
      if (isDebugMode) console.error("Error fetching users info");
    }
  } catch (error) {
    if (isDebugMode) console.error('Error:', error);
  }
  return [];
};

function TraineesCards() {
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { adminId, token } = currentLocation.state || {};
  const [trainees, setTrainees] = useState([]);
  const [filter, setFilter] = useState({ name: '', phone: '', email: '' });
  const [tabIndex, setTabIndex] = useState(2);
  const [file, setFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
    const [selectedTraineeId, setSelectedTraineeId] = useState(null); // state for selected trainee ID

  const [formData, setFormData] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: ''
  });
  const [loading, setLoading] = useState(false);  // Track loading state
  const theme = useTheme();

  const fetchData = async () => {
    const trainees = await getTrainees(adminId, token);
    setTrainees(trainees);
  };

  const handleTabChange = (event, newValue) => {
    if (isDebugMode) console.log('handleTabChange', newValue);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter(prevFilter => ({ ...prevFilter, [name]: value }));
  };

  const handleRowClick = (userId) => {
    // navigate('/admin_user_details', { state: { adminId, token, userId } });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const openDialogWithTraineeId = (traineeId) => {
    setSelectedTraineeId(traineeId); // Set the selected trainee ID
    setOpenDialog(true);  // Open the dialog
  };

const addCard = async (adminID, token, selectedTraineeId) => {
  if (!file) {
    console.log("Please complete all fields and select a file");
    return;
  }

  setLoading(true);  // Show spinner while uploading

  // Convert the file to a base64 string using FileReader
  const reader = new FileReader();

  // Wrap the file conversion and upload in a Promise
  const base64File = new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extract base64 data
    reader.onerror = reject;
    reader.readAsDataURL(file); // Convert the file to base64 (data URL)
  });

  try {
    const base64EncodedFile = await base64File; // Wait for the file to be converted

    const uploadData = new FormData();

    // Append the base64 file to the FormData object
        uploadData.append('base64EncodedFile', base64EncodedFile);
    uploadData.append('admin_id', adminID);
    uploadData.append('token', token);
    uploadData.append('trainee_id', selectedTraineeId);

    // Append form fields (field1, field2, etc.)
    Object.keys(formData).forEach(key => {
      uploadData.append(key, formData[key]);
    });

    // Send the request
    const response = await fetch(process.env.REACT_APP_BASE_URL + 'api/upload_to_create_card', {
      method: 'POST',
      body: uploadData,  // Use the uploadData for file uploads
    });

    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      console.log("File uploaded successfully");
      setOpenDialog(false);  // Close dialog after successful upload
    } else {
      console.error("Error uploading file");
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  } finally {
    setLoading(false);  // Hide spinner when upload is complete
  }
};



  const getCard = () => {
    console.log("Get card clicked");
  };

  useEffect(() => {
    fetchData();
  }, [adminId, token]);

  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 0 }}>
        <Avatar
          sx={{ m: 1, bgcolor: 'secondary.main', width: 100, height: 100 }}
          alt="Logo"
          src={myLogo}
        />
        <Typography component="h1" variant="h5" sx={{ marginTop: 2, color: theme.palette.buttonBackground.contrastText }}>
          מתאמנים - קלפי שחקן
        </Typography>

        <Box sx={{ width: '100%', maxWidth: 600 }}>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              label="סנן לפי שם "
              variant="outlined"
              size="small"
              name="name"
              value={filter.name}
              onChange={handleFilterChange}
              InputProps={{
                style: { color: theme.palette.primary.contrastText },
              }}
              InputLabelProps={{
                style: { color: theme.palette.primary.contrastText },
              }}
            />
          </Box>

          <Box sx={{ overflowY: 'auto' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
              <thead>
                <tr>
                  <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>שם</th>
                  <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>קבוצה</th>
                  <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>שנתון</th>
                  <th style={{ textAlign: 'right', padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>קלף</th>
                </tr>
              </thead>
              <tbody>
                {trainees
                  .filter(trainee => {
                    const nameMatch = trainee.name.toLowerCase().includes(filter.name.toLowerCase());
                    return nameMatch;
                  })
                  .map(trainee => (
                    <tr key={trainee.id} onClick={() => handleRowClick(trainee.id)} style={{ cursor: 'pointer' }}>
                      <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>{trainee.name}</td>
                      <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>{trainee.team}</td>
                      <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>{trainee.year}</td>

                      <td style={{ padding: '8px', color: theme.palette.buttonBackground.contrastText, borderBottom: `2px solid ${theme.palette.primary.main}` }}>
                        {trainee.card !== null && trainee.card === true
                          ? (
                            <IconButton sx={{ color: theme.palette.buttonBackground.contrastText }} onClick={getCard}>
                              <CardIcon />
                            </IconButton>
                          )
                          : (
                            <IconButton sx={{ color: theme.palette.buttonBackground.contrastText }} onClick={() => openDialogWithTraineeId(trainee.id)}>
                              <AddCircleIcon />
                            </IconButton>
                          )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Box>
        </Box>
      </Box>

      {/* Dialog for file upload */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>העלאת קלף</DialogTitle>
        <DialogContent>
          <Typography variant="body2">Trainee ID: {selectedTraineeId}</Typography> {/* Display trainee ID here */}
          <input type="file" onChange={handleFileChange} style={{ marginBottom: '20px' }} />
          <Typography variant="body2">{file ? `קובץ נבחר: ${file.name}` : 'לא נבחר קובץ'}</Typography>
          {['name', 'year', 'pac', 'sho', 'pas', 'dri', 'def', 'phy'].map(field => (
            <TextField
              key={field}
              label={`${field}`}
              variant="outlined"
              fullWidth
              margin="normal"
              value={formData[field]}
              name={field}
              onChange={handleInputChange}
            />
          ))}
        </DialogContent>
        <DialogActions>
          <button onClick={() => setOpenDialog(false)} disabled={loading}>סגור</button>
          <button
              onClick={() => addCard(adminId, token, selectedTraineeId)}
              disabled={loading || !file}>
              {loading ? <CircularProgress size={24} /> : 'העלאה'}
          </button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default TraineesCards;
