import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import {
  useLocation as useRouterLocation,
  useNavigate,
} from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddTrainerDialog from "./AddTrainerDialog"; // Import the new dialog component
import NavBar from "../NavBar"; // Import NavBar
import { useTheme } from "@mui/material/styles"; // Import useTheme for access to theme
import { Box, Typography, Grid, Paper } from "@mui/material";

import myLogo from "../../ez.jpeg"; // Adjust the path based on your project structure

const isDebugMode = process.env.REACT_APP_DEBUG === "true";

// Helper function to fetch user info
const getUserInfo = async (userId, token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "api/user_info",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
      }
    );
    const jsonResponse = await response.json();
    if (isDebugMode) console.log(jsonResponse);
    if (jsonResponse.success) {
      return jsonResponse.user_info || []; // Return array if available
    } else {
      if (isDebugMode) console.error("Error fetching user info");
    }
  } catch (error) {
    if (isDebugMode) console.error("Error:", error);
  }
  return [];
};

// Helper function to fetch trainers
const getTrainers = async (userId, token) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "api/get_trainees",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ user_id: userId, token: token }), // Use user_id and token for fetch
      }
    );
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.trainers || []; // Return array if available
    } else {
      if (isDebugMode) console.error("Error getting trainers");
    }
  } catch (error) {
    if (isDebugMode) console.error("Error:", error);
  }
  return [];
};

// Helper function to add a new trainer
const addTrainer = async (trainer) => {
  try {
    const response = await fetch(
      process.env.REACT_APP_BASE_URL + "api/add_trainer",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(trainer),
      }
    );
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return true;
    } else {
      if (isDebugMode) console.error("Error adding trainer");
    }
  } catch (error) {
    if (isDebugMode) console.error("Error:", error);
  }
  return false;
};

function UserInfo() {
  const theme = useTheme(); // Access the theme for color adjustments
  const navigate = useNavigate();
  const currentLocation = useRouterLocation();
  const { userId, token } = currentLocation.state || {}; // Extract userId and token
  const [userInfo, setUserInfo] = useState([]);
  const [trainers, setTrainers] = useState([]); // State for trainers
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false); // State for dialog open

  const [newTrainer, setNewTrainer] = useState({
    name: "",
    phone: "",
    team: "",
  }); // State for new trainer details
  const [tabIndex, setTabIndex] = useState(3); // Default tab index for 'User Info'

  useEffect(() => {
    const fetchData = async () => {
      const user = await getUserInfo(userId, token);
      if (!user.length) {
        // Check if user info is not fetched correctly
        navigate("/login"); // Redirect to login if user info is invalid
        return;
      }
      setUserInfo(user);
      const fetchedTrainers = await getTrainers(userId, token);
      setTrainers(fetchedTrainers);

      //const fetchedOrders = await getUniformOrders(userId, token);
      //setUniformOrders(fetchedOrders);
    };
    fetchData();
  }, [userId, token, navigate]);

  const handleOpenDialog = () => setOpen(true);
  const handleCloseDialog = () => setOpen(false);

  const handleAddTrainer = async (trainer) => {
    console.log("Trainer to add:", trainer);
    await addTrainer({ ...trainer, user_id: userId, token: token });
    setDialogOpen(false);
    // Reload trainers from server
    const fetchedTrainers = await getTrainers(userId, token);
    setTrainers(fetchedTrainers);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <CssBaseline />
      <Container
        component="main"
        maxWidth="md"
        className="users-page"
        sx={{ flex: 1, overflowY: "auto", paddingBottom: 80 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 8,
          }}
        >
          <Avatar
            sx={{ m: 1, bgcolor: "secondary.main", width: 100, height: 100, marginBottom: 8}} // Adjust size as needed
            alt="Logo"
            src={myLogo}
          />
          <Typography
            component="h1"
            variant="h5"
            sx={{ color: theme.palette.primary.contrastText }}
          >
            {/* Any other title text */}
          </Typography>
          <Paper
            elevation={3}
            sx={{
              width: "100%",
              maxWidth: 600,
              padding: 3,
              borderRadius: "12px",
              backgroundColor: theme.palette.dialogBackground.main,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: theme.palette.primary.contrastText,
                fontWeight: "bold",
                marginBottom: 2,
              }}
            ></Typography>
            {userInfo[0] ? (
              <Grid container spacing={2}>
                {/* Name and Email */}
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {/* Name */}
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: theme.palette.primary.contrastText,
                          fontWeight: "bold",
                        }}
                      >
                        שם:
                      </Typography>
                      <Typography
                        sx={{ color: theme.palette.primary.contrastText }}
                      >
                        {userInfo[0].name || "N/A"}
                      </Typography>
                    </Grid>
                    {/* Email */}
                    <Grid item xs={6}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color: theme.palette.primary.contrastText,
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        :Email
                      </Typography>
                      <Typography
                        sx={{
                          color: theme.palette.primary.contrastText,
                          textAlign: "left",
                        }}
                      >
                        {userInfo[0].email || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Phone */}
                <Grid item xs={12} sx={{ textAlign: "center" }}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: theme.palette.primary.contrastText,
                      fontWeight: "bold",
                    }}
                  >
                    טלפון:
                  </Typography>
                  <Typography
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    {userInfo[0].phone || "N/A"}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  color: theme.palette.primary.contrastText,
                  marginTop: 2,
                }}
              >
                אין מידע על המשתמש.
              </Typography>
            )}
          </Paper>

          <Box sx={{ width: "100%", maxWidth: 600, marginTop: 4 }}>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >

              <Typography
                variant="h6"
                sx={{ color: theme.palette.primary.contrastText, marginTop: 4}}
              >רשימת מתאמנים</Typography>

            </Box>




{/* Trainers Display */}
<Box

  sx={{
    maxHeight: "400px",
    overflowY: "auto",
    display: "block",
    backgroundColor: theme.palette.dialogBackground.main,
    marginBottom: 4,
  }}
>
  {trainers.length > 0 ? (
    trainers.map((trainer, index) => (
      <Box
        key={trainer.id}
        sx={{
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "flex-start",
          padding: 2,
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        }}
      >
        {/* Profile Photo */}
        <Avatar
          alt={trainer.name}
          src={trainer.profilePhoto} // Assume there's a profilePhoto field or a default image.
          sx={{
            width: 64,
            height: 64,
            marginLeft: 2, // Spacing between photo and details
          }}
        />

        {/* Trainee Details */}
        <Box sx={{ flex: 1 }}>
          {/* Name */}
          <Typography
            sx={{
              color: theme.palette.primary.contrastText,
              fontWeight: "bold",
              marginBottom: 1,
            }}
          >
            {trainer.name}
          </Typography>

          {/* Other Details */}
          <Typography
            sx={{ color: theme.palette.primary.contrastText, fontSize: "0.9rem" }}
          >
            טלפון: {trainer.phone || "N/A"}
          </Typography>
          <Typography
            sx={{ color: theme.palette.primary.contrastText, fontSize: "0.9rem" }}
          >
            קבוצה: {trainer.team || "N/A"}
          </Typography>
          <Typography
            sx={{ color: theme.palette.primary.contrastText, fontSize: "0.9rem" }}
          >
            שנתון: {trainer.year || "N/A"}
          </Typography>

<Typography sx={{ color: theme.palette.primary.contrastText, fontSize: "0.9rem" }}>
  קבוצות אימון: {trainer.groups && trainer.groups.length > 0
    ? trainer.groups.map(group => group.group_name).join(", ")
    : "N/A"}
</Typography>


        </Box>
      </Box>
    ))
  ) : (
    <Typography
      sx={{
        color: theme.palette.primary.contrastText,
        textAlign: "center",
      }}
    >
      לא נמצאו מתאמנים.
    </Typography>
  )}
</Box>



              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
              >
                הוספת מתאמן
              </Button>

              <AddTrainerDialog
                open={open}
                onClose={handleCloseDialog}
                onAddTrainer={handleAddTrainer}
              />



          </Box>
        </Box>
      </Container>
      {/* NavBar Component */}
      <NavBar
        userId={userId}
        token={token}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        sx={{ position: "fixed", bottom: 0, width: "100%" }}
      />
    </Box>
  );
}

export default UserInfo;
